export const backendUrl = window.location.hostname.includes('localhost')
  ? 'http://localhost:8080'
  : 'https://ambul-90ef4.uc.r.appspot.com';

// export const backendUrl = 'https://ambul-90ef4.uc.r.appspot.com';
// export const backendUrl = 'http://localhost:8080';

export const viewOptions = [
  {
    value: 'camera',
    name: 'Camera view',
  },
  {
    value: 'birds-eye',
    name: `Bird's-eye view`,
  },
];
export const mapTypeOptions = [
  {
    value: 'heatmap',
    name: `Heatmap`,
  },
  {
    value: 'routes',
    name: 'Routes',
  },
  {
    value: 'number',
    name: 'Number',
  },
  {
    value: 'percentage',
    name: 'Percentage',
  },
];
export const dataTypeOptions = [
  {
    value: 'traffic',
    name: `Traffic`,
  },
  {
    value: 'average-speed',
    name: 'Average speed',
  },
  {
    value: 'average-time',
    name: 'Average time',
  },
];
export const shopperTypeOptions = [
  {
    value: 'all',
    name: `All`,
  },
  {
    value: 'buyers',
    name: 'Buyers',
  },
  {
    value: 'non-buyers',
    name: 'Non-buyers',
  },
];
