import React from 'react';
import DashboardLayout from 'components/helpers/DashboardLayout';
import { useLocation } from 'react-router-dom';
import LocationPrompt from 'components/main/LocationPrompt';
import LocationTilesContainer from 'components/main/LocationTilesContainer';
import { useRecoilValue } from 'recoil';
import * as atoms from 'recoil/atoms';
import { useDynamo } from 'hooks/use-dynamo';
import LocationAreas from 'components/main/LocationAreas';

export default function AreasPage() {
  const { loadLocation } = useDynamo();
  const account = useRecoilValue(atoms.account);
  const location = useLocation();

  //   React.useEffect(() => {
  //     const p = location.pathname.replace('/location/', '');
  //     if (p.length > 0) {
  //       const obj = {
  //         pk: account.pk,
  //         sk: `location#${p}`,
  //       };
  //       // console.log('LocationPage', obj);
  //       loadLocation(obj);
  //     }
  //   }, []);

  // Total unique visitors
  // Camera list
  // Add camera
  // Add group (not yet)

  return (
    <DashboardLayout>
      <br></br>
      <br></br>
      <LocationAreas />
    </DashboardLayout>
  );
}
