import React from 'react';
import { useNavigate } from 'react-router-dom';
import HeaderLanding from 'components/landing/HeaderLanding';
import Button from '@mui/material/Button';
// import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import DeeperExplanation from 'components/landing/DeeperExplanation';
import HowWeDoIt from 'components/landing/HowWeDoIt';
import Concerns from 'components/landing/Concerns';
import JoinBeta from 'components/landing/JoinBeta';
import Footer from 'components/landing/Footer';
// import TextField from '@mui/material/TextField';
// import FormControl from '@mui/material/FormControl';
// import undraw_prototype from 'assets/undraw_prototype.svg';
// import undraw_prototype from 'assets/undraw_team.svg';
// import bottom from 'assets/bottom.svg';
import pattern from 'assets/pattern2.svg';
import pink from 'assets/pink.png';
import colors from 'assets/royal-heath.jpg';

function Landing() {
  const navigate = useNavigate();
  const myRef = React.useRef(null);

  const executeScroll = () => myRef.current.scrollIntoView();

  return (
    <>
      <HeaderLanding />
      <div
        // className="mesh"
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          minHeight: '80vh',
          height: 'fit-content',
          // backgroundColor: 'blue',
        }}
      >
        <div
          style={{
            textAlign: 'center',
            // backgroundColor: 'red',
            padding: '40px 10px',
          }}
        >
          {/* <h1>Real World Analytics</h1> */}
          <div
            className="highlight_text"
            style={{
              fontFamily: `'Roboto', sans-serif`,
              fontSize: '80px',
              fontWeight: 700,
              marginBottom: '20px',
            }}
          >
            Customer Journey Analytics
          </div>
          <h4>
            Finally understand what your customers<br></br> are doing inside
            your store
          </h4>
          <br></br>
          <br></br>
          <Button
            color="primary"
            variant="contained"
            // onClick={() => navigate('/signin')}
            onClick={executeScroll}
            size="large"
          >
            Join limited beta
          </Button>
          <br></br>
          <br></br>
          <br></br>
        </div>

        {/* <img src={pink} width={400} alt={'undraw_prototype'} /> */}
        {/* </div> */}
        {/* <img src={pattern} width={400} alt={'undraw_prototype'} /> */}
      </div>
      {/* <img src={undraw_prototype} width={400} alt={'undraw_prototype'} /> */}
      {/* <img src={bottom} width={'100%'} alt={'undraw_prototype'} /> */}
      {/* <br></br>
      <br></br> */}
      {/* <br></br>
      <br></br> */}
      <DeeperExplanation />
      <HowWeDoIt />
      <Concerns />
      <JoinBeta refProp={myRef} />

      <Footer />
    </>
  );
}

export default Landing;
