import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from 'hooks/use-auth';
import { useRecoilState } from 'recoil';
import * as atoms from 'recoil/atoms';

function SignUp() {
  const [email, setEmail] = useRecoilState(atoms.signUpEmail);
  const [password, setPassword] = useRecoilState(atoms.signUpPassword);
  const [error, setError] = useRecoilState(atoms.signUpError);
  const { onSignUp } = useAuth();
  const navigate = useNavigate();

  function onEmailChange(event) {
    setEmail(event.target.value);
    if (error) {
      setError('');
    }
  }

  function onPasswordChange(event) {
    setPassword(event.target.value);
    if (error) {
      setError('');
    }
  }

  return (
    <>
      <div style={{ textAlign: 'center', padding: '20px' }}>
        <div>sign up</div>
        <br></br>
        <br></br>
        <input value={email} type="text" onChange={onEmailChange} />
        <br></br>
        <br></br>
        <input value={password} type="password" onChange={onPasswordChange} />
        <br></br>
        <br></br>
        <div style={{ color: 'red' }}>{error}</div>
        <br></br>
        <button onClick={() => onSignUp(email, password)}>sign up</button>
        <br></br>
        <br></br>
        <div onClick={() => navigate('/')}>
          Already have an account? sign in
        </div>
      </div>
    </>
  );
}

export default SignUp;
