import React from 'react';
import moment from 'moment';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import { useData } from 'hooks/use-data';
import AddIcon from '@mui/icons-material/Add';
import LockIcon from '@mui/icons-material/Lock';
import { calculateWidth, calculateHeight } from 'helpers/heatmapHelper';
import { useRecoilValue } from 'recoil';
import * as atoms from 'recoil/atoms';
import { useWindowSize } from '@react-hook/window-size';
import { generate } from 'short-uuid';
import AreaTile from 'components/main/AreaTile';

function createBoxes(location, canvasHeight, canvasWidth) {
  if (!location.height || !location.width) {
    return [];
  }
  const { height = 100, width = 100, areas = [] } = location;
  // x = 100
  // canvasWidth = 1000
  // width = 2000
  // point1.x = x * canvasWidth / width
  return (
    areas.map((area) => {
      const point1 = {
        x: (area.min_x * canvasWidth) / width,
        y: (area.min_y * canvasHeight) / height,
      };
      const point2 = {
        x: (area.max_x * canvasWidth) / width,
        y: (area.max_y * canvasHeight) / height,
      };

      const temp = new Box(
        point1,
        point2,
        area.id,
        area.name,
        false,
        area.locked,
      );
      // console.log(temp);
      return temp;
    }) || []
  );
}

function getDistance(x1, y1, x2, y2) {
  const y = x2 - x1;
  const x = y2 - y1;
  return Math.sqrt(x * x + y * y);
}

function Box(point1, point2, id, name, active, locked) {
  this.point1 = point1;
  this.point2 = point2;
  this.id = id;
  this.name = name;
  this.width = Math.abs(point1.x - point2.x);
  this.height = Math.abs(point1.y - point2.y);
  this.active = active;
  this.locked = locked;
  this.radius = 10;
  this.draggingPoint1 = false;
  this.draggingPoint2 = false;

  this.draw = (ctx) => {
    if (this.locked) {
      ctx.strokeStyle = 'black';
    } else if (this.active) {
      ctx.strokeStyle = 'red';
    } else {
      ctx.strokeStyle = 'blue';
    }
    ctx.lineWidth = 1;
    ctx.beginPath();
    ctx.moveTo(this.point1.x, this.point1.y);
    ctx.lineTo(this.point2.x, this.point1.y);
    ctx.lineTo(this.point2.x, this.point2.y);
    ctx.lineTo(this.point1.x, this.point2.y);
    ctx.closePath();
    ctx.fillStyle = 'rgba(0, 0, 255, .2)';
    ctx.fill();
    ctx.fillStyle = 'rgba(0, 0, 255, 1)';
    ctx.stroke();
    ctx.font = '15px Arial';
    ctx.textAlign = 'center';

    const middleX = (this.point1.x + this.point2.x) / 2;
    const middleY = (this.point1.y + this.point2.y) / 2;
    ctx.fillText(this.name, middleX, middleY + 5);

    if (this.active) {
      ctx.beginPath();
      ctx.arc(this.point1.x, this.point1.y, this.radius, 0, 2 * Math.PI);
      ctx.stroke();
      ctx.beginPath();
      ctx.arc(this.point2.x, this.point2.y, this.radius, 0, 2 * Math.PI);
      ctx.stroke();
    }
  };

  this.move = (x, y) => {
    if (this.draggingPoint1) {
      this.point1.x = x;
      this.point1.y = y;
    } else if (this.draggingPoint2) {
      this.point2.x = x;
      this.point2.y = y;
    } else {
      this.point1.x = x - this.width / 2;
      this.point1.y = y - this.height / 2;
      this.point2.x = x + this.width / 2;
      this.point2.y = y + this.height / 2;
    }
  };

  this.changeName = (newName) => {
    this.name = newName;
  };

  this.setActive = () => {
    this.active = true;
  };
  this.setInactive = () => {
    this.active = false;
  };

  this.isPointInBox = (x, y) => {
    if (this.locked) {
      return false;
    }
    this.draggingPoint1 = false;
    this.draggingPoint2 = false;
    // console.log(getDistance(x, y, point1.x, point1.y));
    // console.log(getDistance(x, y, point2.x, point2.y));

    if (this.active) {
      if (getDistance(x, y, point1.x, point1.y) <= this.radius) {
        this.draggingPoint1 = true;
        return true;
      } else if (getDistance(x, y, point2.x, point2.y) <= this.radius) {
        this.draggingPoint2 = true;
        return true;
      }
    }

    if (
      x > Math.min(this.point1.x, this.point2.x) &&
      x < Math.max(this.point1.x, this.point2.x) &&
      y > Math.min(this.point1.y, this.point2.y) &&
      y < Math.max(this.point1.y, this.point2.y)
    ) {
      return true;
    }
    return false;
  };
}

let point1 = {};
let point2 = {};
let dragging = false;
let draggingElement;
let canvas;
let ctx;

export default function LocationAreas() {
  const [windowWidth] = useWindowSize();
  const currentLocation = useRecoilValue(atoms.currentLocation);
  // const width = calculateWidth({}, currentLocation);
  // const width = 600;
  const [width, setWidth] = React.useState(100);
  const [height, setHeight] = React.useState(100);
  // const height = calculateHeight({}, currentLocation);
  const canvasRef = React.useRef();
  const canvasContainerRef = React.useRef();
  const [name, setName] = React.useState('');
  const { onNewCamera, saveLocationAreas } = useData();
  const [elements, setElements] = React.useState(
    createBoxes(currentLocation, height, width),
  );
  // const [editIndex, setEditIndex] = React.useState(-1);
  const [changed, setChanged] = React.useState(false);
  const [updatedTimestamp, setUpdatedTimestamp] = React.useState();

  React.useEffect(() => {
    // console.log('=>', windowWidth);
    if (currentLocation?.height && currentLocation?.width) {
      const newWidth = canvasContainerRef.current.offsetWidth;
      // console.log('---->', newWidth);
      const newHeight =
        (currentLocation?.height * newWidth) / currentLocation?.width;

      setHeight(parseInt(newHeight));
      setWidth(parseInt(newWidth));
      setElements(createBoxes(currentLocation, newHeight, newWidth));
    }
  }, [windowWidth, currentLocation]);

  React.useLayoutEffect(() => {
    canvas = canvasRef.current;
    canvas.width = width;
    canvas.height = height;
    ctx = canvas.getContext('2d');

    // if (currentLocation?.areas?.length > 0 && elements.length === 0) {
    //   setElements(createBoxes(ctx, currentLocation, height, width));
    // } else {
    // }
    requestAnimationFrame(drawAll);

    function drawAll() {
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      // ctx.clearRect(
      //   0 - OFFSET_X,
      //   0 - OFFSET_Y,
      //   canvas.width / SCALE,
      //   canvas.height / SCALE,
      // );
      if (dragging && !draggingElement) {
        ctx.strokeStyle = 'black';
        ctx.beginPath();
        ctx.lineWidth = 1;
        ctx.moveTo(point1.x, point1.y);
        ctx.lineTo(point2.x, point1.y);
        ctx.lineTo(point2.x, point2.y);
        ctx.lineTo(point1.x, point2.y);
        ctx.closePath();
        ctx.stroke();
      }
      // drawElements(ctx);
      elements.forEach((e) => e.draw(ctx));

      requestAnimationFrame(drawAll);
    }

    // const shapes = drawElements(ctx, elements);

    canvas.addEventListener('mousedown', onMouseDown);
    canvas.addEventListener('mouseup', onMouseUp);
    canvas.addEventListener('mousemove', onMouseMove);

    return () => {
      canvas.removeEventListener('mousedown', onMouseDown);
      canvas.removeEventListener('mouseup', onMouseUp);
      canvas.removeEventListener('mousemove', onMouseMove);
    };
  }, [elements, width]);

  React.useEffect(() => {
    // save elements in DB
    if (changed) {
      // saveLocationAreas(elements, height, width);
      setUpdatedTimestamp(Date.now());
      setChanged(false);
    }
  }, [elements]);

  function onMouseDown(event) {
    const x = event.offsetX;
    const y = event.offsetY;
    point1 = { x, y };
    point2 = { x, y };
    // console.log('mousedown', x, y);

    draggingElement = elements.find((e) => e.isPointInBox(x, y));
    elements.forEach((el) =>
      el.id === draggingElement?.id ? el.setActive() : el.setInactive(),
    );
    dragging = true;
  }

  function onMouseUp(event) {
    const x = event.offsetX;
    const y = event.offsetY;
    point2 = { x, y };
    setChanged(true);
    if (
      !draggingElement &&
      Math.abs(point1.x - point2.x) > 20 &&
      Math.abs(point1.y - point2.y) > 20
    ) {
      const uid = generate();
      const newBox = new Box(point1, point2, uid, uid, true, false);
      // const newBox = new Box(point1, point2, boxIndex, boxIndex, true, false);
      setElements((oldArray) => [...oldArray, newBox]);
    } else if (draggingElement) {
      const newElements = elements.map((e) => {
        if (e.id === draggingElement.id) {
          return new Box(
            draggingElement.point1,
            draggingElement.point2,
            draggingElement.id,
            draggingElement.name,
            true,
            false,
          );
        }
        return e;
      });
      // console.log(newElements);
      setElements(newElements);
    }
    dragging = false;
  }

  function onMouseMove(event) {
    const x = event.offsetX;
    const y = event.offsetY;
    point2 = { x, y };
    if (dragging && draggingElement) {
      draggingElement.move(x, y);
    }
  }

  // function removeElement(el) {
  //   // console.log(el);
  //   setElements(elements.filter((e) => e.index !== el.index));
  // }

  // function startEdit(el) {
  //   setEditIndex(el.index);
  //   setName(el.name);
  // }

  // function saveEdit() {
  //   // save it here
  //   const newElements = elements.map((e) => {
  //     if (e.index === editIndex) {
  //       return new Box(
  //         e.point1,
  //         e.point2,
  //         e.index,
  //         name || e.index,
  //         false,
  //         e.locked,
  //       );
  //     }
  //     return e;
  //   });
  //   // console.log(newElements);
  //   setElements(newElements);
  //   // setEditIndex(-1);
  //   setName('');
  // }
  // console.log(currentLocation);

  return (
    <div style={{ padding: '20px', paddingTop: '0px' }}>
      <h3>Areas</h3>
      <div>{currentLocation?.name}</div>
      <br></br>
      <div>Total areas: {currentLocation?.areas?.length}</div>
      <div>
        Location = height: {currentLocation?.height}, width:{' '}
        {currentLocation?.width}
      </div>
      <div>
        Canvas = height: {height}, width: {width}
      </div>
      <div>
        {updatedTimestamp &&
          `Last saved: ${moment(updatedTimestamp).format(
            'MMMM Do YYYY, h:mm:ss a',
          )}`}
      </div>
      <br></br>
      <Grid container spacing={2}>
        <Grid item xs={9}>
          <div ref={canvasContainerRef} style={{ width: '100%' }}>
            <canvas
              ref={canvasRef}
              style={{
                backgroundColor: 'white',
                // backgroundUrl: `url(${currentLocation?.map_url})`,
                backgroundImage: `url(${currentLocation?.map_url})`,
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                height,
                width: canvasContainerRef?.current?.offsetWidth || 1000,
                outline: '2px solid black',
                outlineOffset: '0px',
              }}
            />
          </div>
        </Grid>

        <Grid item xs={3}>
          {elements.length === 0 && (
            <div>
              Its looks like you don't have any areas created yet. To get
              started, click and drag your mouse over an area of the map. Then
              name it.
            </div>
          )}
          {elements.map((e) => {
            return <AreaTile key={e.id} element={e} />;
          })}

          {/* <div>
            {elements.length === 0 && (
              <div>
                Its looks like you don't have any areas created yet. To get
                started, click and drag your mouse over an area of the map. Then
                name it.
              </div>
            )}
            {elements.map((e) => {
              return (
                <Card
                  key={e.index}
                  style={{
                    border: 'solid black 1px',
                    width: '100%',
                    marginBottom: '10px',
                  }}
                >
                  <div style={{ padding: '10px' }}>
                    {e.index === editIndex ? (
                      <>
                        <form onSubmit={() => saveEdit()}>
                          <FormControl style={{ paddingBottom: '10px' }}>
                            <TextField
                              size="small"
                              label="Name"
                              variant="outlined"
                              value={name}
                              type="name"
                              onChange={(event) => setName(event.target.value)}
                            />
                          </FormControl>
                          <br></br>
                          
                          <div style={{ textAlign: 'right' }}>
                            <Button
                              variant="contained"
                              size="small"
                              type="submit"
                            >
                              Save
                            </Button>
                          </div>
                        </form>
                      </>
                    ) : (
                      <div>
                        <h6
                          style={{
                            // fontWeight: 'bold',
                            marginBottom: '20px',
                          }}
                        >
                          {e.name}
                        </h6>
                        {e.locked ? (
                          <div
                            style={{
                              display: 'flex',
                              // justifyContent: 'space-between',
                              alignItems: 'flex-end',
                            }}
                          >
                            <>
                              <LockIcon fontSize="small" />
                            </>
                          </div>
                        ) : (
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              // alignItems: 'flex-end',
                            }}
                          >
                            <Button
                              variant="outlined"
                              size="small"
                              onClick={() => removeElement(e)}
                            >
                              Remove
                            </Button>
                            <Button
                              variant="contained"
                              size="small"
                              onClick={() => startEdit(e)}
                            >
                              Edit
                            </Button>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </Card>
              );
            })}
          </div> */}
        </Grid>
      </Grid>
    </div>
  );
}
