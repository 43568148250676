export function parseURL() {
  const routes = [
    'signin',
    'signup',
    'forgotpassword',
    'data',
    'design',
    'camera',
    'group',
    'areas',
    'cameras',
  ];
  // console.log(window.location.pathname.split('/'));
  const [locationIdFromUrl, otherIdFromUrl] = window.location.pathname
    .split('/')
    .filter((name) => name.length > 1 && routes.indexOf(name) === -1);
  // console.log({ locationIdFromUrl, otherIdFromUrl });
  if (window.location.pathname.includes('camera')) {
    // console.log([locationIdFromUrl, undefined, otherIdFromUrl]);
    return [locationIdFromUrl, otherIdFromUrl, undefined];
  }
  return [locationIdFromUrl, undefined, otherIdFromUrl];
}
