import {
  getDocs,
  getDoc,
  doc,
  collection,
  addDoc,
  // setDoc,
  // onSnapshot,
  query,
  limit,
  // exists,
  // docs,
  updateDoc,
  arrayUnion,
} from 'firebase/firestore';
import { backendUrl } from 'helpers/constants';
import { db, auth } from 'helpers/initializeFirebase';

export async function getUserByEmail(email) {
  const docRef = doc(db, 'users', email);
  const docSnap = await getDoc(docRef);
  if (docSnap.exists()) {
    const data = docSnap.data();
    return { ...data, email };
  }
  return {};
}

export async function getAccountById(accountId) {
  const docRef = doc(db, 'account', accountId);
  return await getDocument(docRef);
}

export async function getLocationById(accountId, locationId) {
  const docRef = doc(db, `account/${accountId}/locations/${locationId}`);
  return await getDocument(docRef);
}

export async function getCameraById(accountId, locationId, cameraId) {
  const docRef = doc(
    db,
    `account/${accountId}/locations/${locationId}/cameras/${cameraId}`,
  );
  return await getDocument(docRef);
}

export async function getDocument(ref) {
  const docSnap = await getDoc(ref);
  if (docSnap.exists()) {
    return { id: docSnap.id, ...docSnap.data() };
  }
  return {};
}

export async function getAreasByLocationId(accountId, locationId) {
  const docRef = doc(db, `account/${accountId}/locations/${locationId}`);
  return await getDocument(docRef);
}

export async function createCamera(accountId, location, name) {
  const camerasCollection = collection(
    db,
    `account/${accountId}/locations/${location.id}/cameras`,
  );
  const newDoc = await addDoc(camerasCollection, {
    name,
  });
  const id = newDoc.id;
  const newCameraDoc = doc(db, `account/${accountId}/locations/${location.id}`);
  const newCameraObj = { id, name };
  await updateDoc(newCameraDoc, {
    camera_names: arrayUnion(newCameraObj),
  });
  const tempCameras = [...(location.camera_names || [])];
  tempCameras.push(newCameraObj);
  return {
    ...location,
    camera_names: tempCameras,
  };
}

export async function createVideo(accountId, locationId, camera, name) {
  const videosCollection = collection(
    db,
    `account/${accountId}/locations/${locationId}/cameras/${camera.id}/videos`,
  );
  const newDoc = await addDoc(videosCollection, {
    name,
  });
  const id = newDoc.id;
  const cameraDoc = doc(
    db,
    `account/${accountId}/locations/${locationId}/cameras/${camera.id}`,
  );
  const newVideoObj = { id, name };
  await updateDoc(cameraDoc, {
    video_names: arrayUnion(newVideoObj),
  });
  const tempVideos = [...(camera.video_names || [])];
  tempVideos.push(newVideoObj);
  return {
    ...camera,
    video_names: tempVideos,
  };
}

export async function createBetaUser(email) {
  const betaUsersCollection = collection(db, `beta_users`);
  const newDoc = await addDoc(betaUsersCollection, {
    email,
  });
}

export async function updateLocation(accountId, locationId, areas) {
  const locationDoc = doc(db, `account/${accountId}/locations/${locationId}`);
  await updateDoc(locationDoc, {
    areas,
  });
}
