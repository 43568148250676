import { useNavigate } from 'react-router-dom';
import { useAuth } from 'hooks/use-auth';
import { useRecoilState } from 'recoil';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import * as atoms from 'recoil/atoms';

function SignIn() {
  const [email, setEmail] = useRecoilState(atoms.signInEmail);
  const [password, setPassword] = useRecoilState(atoms.signInPassword);
  const [error, setError] = useRecoilState(atoms.signInError);
  const { onSignIn } = useAuth();
  const navigate = useNavigate();

  function onEmailChange(event) {
    setEmail(event.target.value);
    if (error) {
      setError('');
    }
  }

  function onPasswordChange(event) {
    setPassword(event.target.value);
    if (error) {
      setError('');
    }
  }

  async function submit(event) {
    event.preventDefault();
    await onSignIn(email, password);
    navigate('/');
  }

  return (
    <>
      {/* <h3
        style={{ marginLeft: '50px' }}
        className={'logo'}
        onClick={() => navigate('/')}
      >
        Ambul
      </h3> */}
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <h3
        style={{ textAlign: 'center' }}
        className={'logo_big'}
        onClick={() => navigate('/')}
      >
        Ambul
      </h3>
      <br></br>
      <br></br>
      {/* <Paper */}
      <Card
        // elevation={6}
        // elevation={2}
        // variant="outlined"
        sx={{
          maxWidth: 475,
          margin: 'auto',
          padding: '20px',
          textAlign: 'center',
        }}
      >
        <h5>Sign In</h5>
        <br></br>
        <form onSubmit={submit}>
          <FormControl fullWidth>
            <TextField
              size="small"
              label="Email"
              variant="outlined"
              value={email}
              type="text"
              onChange={onEmailChange}
            />
          </FormControl>
          <br></br>
          <br></br>
          <FormControl fullWidth>
            <TextField
              size="small"
              label="Password"
              variant="outlined"
              value={password}
              type="password"
              onChange={onPasswordChange}
            />
          </FormControl>
          <br></br>
          <div style={{ color: 'red' }}>{error}</div>
          <br></br>
          <Button
            sx={{ width: '100%' }}
            color="primary"
            variant="contained"
            type="submit"
          >
            Sign in
          </Button>
        </form>
        <br></br>
        <br></br>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <Button onClick={() => navigate('/forgotpassword')}>
            Forgot Password
          </Button>
          <Button onClick={() => navigate('/signup')}>Sign Up</Button>
        </Box>
        {/* </Paper> */}
      </Card>
    </>
  );
}

export default SignIn;
