import React from 'react';
import { createRoot } from 'react-dom/client';
import App from 'components/helpers/App';
import { theme } from 'styles/theme';
import { RecoilRoot } from 'recoil';
import { ThemeProvider } from '@mui/material/styles';
import 'styles/index.css';

import { Amplify } from 'aws-amplify';
import awsconfig from './aws-exports';
Amplify.configure(awsconfig);

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
  <React.StrictMode>
    <RecoilRoot>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </RecoilRoot>
  </React.StrictMode>,
);
