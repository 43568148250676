import React from 'react';
import { useRecoilValue } from 'recoil';
import * as atoms from 'recoil/atoms';
import Card from '@mui/material/Card';
// import { useData } from '../../hooks/use-data';

export default function AreaTile({ element }) {
  const [edit, setEdit] = React.useState(false);
  const [str, setStr] = React.useState(element.name || '');
  const [locked, setLocked] = React.useState(element.locked || false);
  //   const { updateLocationData } = useData();
  const currentLocation = useRecoilValue(atoms.currentLocation);

  function deleteArea(e) {
    console.log('delete');
    e.preventDefault();
    const { areas } = currentLocation;
    console.log(areas);
    const newAreas = areas.filter((area) => area.id !== element.id);
    console.log(newAreas);
    // updateLocationData({ ...currentLocation, areas: newAreas });
    setEdit(false);
  }

  function submit(e) {
    e.preventDefault();
    const { areas } = currentLocation;
    const newAreas = areas.map((area) => {
      if (area.id === element.id) {
        return { ...area, name: str, locked };
      }
      return area;
    });
    // updateLocationData({ ...currentLocation, areas: newAreas });
    setEdit(false);
  }

  return (
    <Card
      onDoubleClick={() => setEdit(true)}
      sx={{
        // maxWidth: 250,
        // margin: 'auto',
        padding: '10px',
        // paddingTop: '10px',
        // textAlign: 'center',
        marginBottom: '10px',
        cursor: 'pointer',
      }}
    >
      {edit ? (
        <>
          {element.name} cool {edit && 'edit'}
          <form onSubmit={submit}>
            <input
              className="full_width"
              value={str}
              onChange={(e) => setStr(e.target.value)}
            />
            <span>Locked:</span>
            <input
              type="checkbox"
              checked={locked}
              onChange={(e) => setLocked(e.target.checked)}
            />
            <div className="button_row">
              <button onClick={deleteArea}>delete</button>
              <button onClick={() => setEdit(false)}>cancel</button>
              <button type="submit">save</button>
            </div>
          </form>
        </>
      ) : (
        <>
          {element.name} {edit && 'edit'}
        </>
      )}
    </Card>
  );
}
