export default function Concerns() {
  return (
    <>
      <div
        style={{
          // backgroundColor: '#222222',
          // backgroundColor: 'blue',
          backgroundColor: '#eee',
          padding: '80px 40px',
          // color: 'white',
          textAlign: 'center',
          fontSize: '18px',
        }}
      >
        <div
          style={{
            maxWidth: '1400px',
            margin: 'auto',
          }}
        >
          {/* <h2 className="highlight_text_dark">
            Concerned about privacy? We are too.
          </h2> */}
          {/* <br></br>
          <br></br>
          <br></br> */}
          <div
            className="break_flex"
            style={{
              textAlign: 'left',
            }}
          >
            <div style={{ width: '100%', padding: '10px 0px' }}>
              {/* <h3>What do we do</h3> */}
              <h2 className="highlight_text_dark">
                Concerned about privacy? We are too.
              </h2>
              {/* <h5>
                We guarantee that no human at Ambul will see any footage.
                Computers process the footage without human help. The human
                being tracked throughout the store will NEVER have a name
                attached to it which ensures 100% anonymity
              </h5> */}
            </div>
            <div style={{ width: '100%', padding: '10px 0px' }}>
              {/* <h5>
                The human being tracked throughout the store will NEVER have a
                name attached to it which ensures 100% anonymity
              </h5> */}
              <div>
                We guarantee that no human at Ambul will see any security
                footage. Computers process the footage without human help.
                <br></br>
                <br></br>
                The human being tracked throughout the store will NEVER have a
                name attached to it which ensures 100% anonymity.
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div
        style={{
          // backgroundColor: '#222222',
          // backgroundColor: 'blue',
          backgroundColor: 'lightgray',
          padding: '40px',
          color: 'white',
          textAlign: 'center',
          fontSize: '24px',
        }}
      >
        <h4>Concerned about the privacy of your customers? We are too!</h4>

        <div>
          We guarantee that no human at Ambul will see any footage. Computers
          process the footage without human help.
        </div>

        <div>
          The human being tracked throughout the store will NEVER have a name
          attached to it which ensures 100% anonymity
        </div>
      </div> */}
    </>
  );
}
