import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from 'hooks/use-auth';
import { useRecoilValue } from 'recoil';
import Button from '@mui/material/Button';
import MenuIcon from '@mui/icons-material/Menu';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import LocationSelect from 'components/main/LocationSelect';
// import Paper from '@mui/material/Paper';
// import TextField from '@mui/material/TextField';
// import FormControl from '@mui/material/FormControl';
import * as atoms from 'recoil/atoms';

// import undraw_prototype from 'assets/undraw_prototype.svg';

export default function HeaderLanding() {
  const [dropdownOpen, setDropdownOpen] = React.useState(false);
  const account = useRecoilValue(atoms.account);
  const user = useRecoilValue(atoms.user);
  const { onSignOut } = useAuth();
  const navigate = useNavigate();

  return (
    <div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '15px 0px',
          maxWidth: '1500px',
          margin: 'auto',
        }}
      >
        <h2 style={{ marginLeft: '50px' }} className={'logo_big'}>
          Ambul
        </h2>
        <Button
          variant="outlined"
          style={{ marginRight: '50px' }}
          onClick={() => navigate('/signin')}
        >
          Sign in
        </Button>
      </div>
    </div>
  );
}
