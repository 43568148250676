import React from 'react';
import Card from '@mui/material/Card';
import AddNewCamera from 'components/main/AddNewCamera';
import AddNewTileAccount from 'components/main/AddNewTileAccount';
import { useRecoilValue } from 'recoil';
import * as atoms from 'recoil/atoms';
// settings icon from mui
import SettingsIcon from '@mui/icons-material/Settings';
import MenuIcon from '@mui/icons-material/Menu';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';

export default function TileMenu({ tile }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <>
      <MenuIcon onClick={handleClick} />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <MenuItem onClick={() => console.log('pin')}>Pin</MenuItem>
        <MenuItem onClick={() => console.log('copy prompt')}>
          Copy prompt
        </MenuItem>
        <MenuItem onClick={() => console.log('duplicate')}>Duplicate</MenuItem>
        <MenuItem onClick={() => console.log('delete')}>Delete</MenuItem>
        {/* <Typography sx={{ p: 2 }}>The content of the Popover.</Typography> */}
      </Popover>
    </>
  );
}
