import React from 'react';
import Header from 'components/helpers/Header';
import Sidebar from 'components/helpers/Sidebar';

function DashboardLayout({ children, page }) {
  return (
    <div
      style={{
        height: '100vh',
        display: 'grid',
        gridTemplateRows: 'auto  1fr',
        // backgroundColor: '#f4f2f0',
      }}
    >
      <Header />
      <>
        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            gap: '1rem',
            alignItems: 'stretch',
            height: '100%',
          }}
        >
          <div
            style={{
              flexBasis: '200px',
              flexGrow: '1',
              alignSelf: 'stretch',
              border: 'solid 1px rgba(0, 0, 0, .52)',
              borderTop: 'none',
              // backgroundColor: 'white',
            }}
          >
            <Sidebar page={page} />
          </div>
          <div
            style={{
              flexBasis: '0',
              flexGrow: '999',
              minWidth: '70%',
              alignSelf: 'stretch',
              paddingRight: '20px',
            }}
          >
            <div
              style={{
                width: '100%',
                maxWidth: '1200px',
                // margin: 'auto',
                // backgroundColor: 'orange',
                // backgroundColor: '#f3f2f0',
                // backgroundColor: '#f9f8f7',
              }}
            >
              {children}
            </div>
          </div>
        </div>
      </>
    </div>
  );
}

export default DashboardLayout;
