import React from 'react';
import Popover from 'components/helpers/Popover';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import AddIcon from '@mui/icons-material/Add';
import { useDynamo } from 'hooks/use-dynamo';

export default function AddNewTileLocation() {
  const { createLocationTile } = useDynamo();
  const [overlay, setOverlay] = React.useState(false);
  const [name, setName] = React.useState('');

  function submit() {
    createLocationTile({
      name,
    });
    setName('');
    setOverlay(false);
  }

  return (
    <>
      <Button onClick={() => setOverlay(true)}>
        {'Add new tile '}
        <AddIcon fontSize="small" />
      </Button>
      <div style={{ textAlign: 'center' }}>
        <Popover open={overlay} onClose={() => setOverlay(false)}>
          <FormControl style={{ paddingRight: '10px' }}>
            <TextField
              size="small"
              label="Name"
              variant="outlined"
              value={name}
              type="name"
              onChange={(event) => setName(event.target.value)}
            />
          </FormControl>
          {/* <br></br> */}
          <Button
            sx={{ marginRight: '10px' }}
            onClick={submit}
            size="small"
            color="primary"
            variant="contained"
            type="submit"
            disabled={!name}
          >
            Submit
          </Button>
          <Button
            // sx={{ width: '100%' }}
            onClick={() => setOverlay(false)}
            size="small"
            color="primary"
            variant="outlined"
          >
            Cancel
          </Button>
        </Popover>
      </div>
    </>
  );
}
