import React from 'react';
import LocationSelect from 'components/main/LocationSelect';
import MenuItem from '@mui/material/MenuItem';
import { useNavigate, useLocation } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import * as atoms from 'recoil/atoms';
import { useDynamo } from 'hooks/use-dynamo';

function Sidebar({ page }) {
  const { loadLocation } = useDynamo();
  const navigate = useNavigate();
  const location = useLocation();
  const account = useRecoilValue(atoms.account);
  const [currentLocation, setCurrentLocation] = useRecoilState(
    atoms.currentLocation,
  );
  // console.log(window.location.pathname.split('/'));
  const isAreas = location.pathname.indexOf('areas') !== -1;
  const isCameras = location.pathname.indexOf('cameras') !== -1;
  const isDashboard = !isAreas;
  const blue = '#0852FD';

  function changeTo(newRoute) {
    if (newRoute === 'dashboard' && !isDashboard) {
      const id = currentLocation.sk.replace('location#', '');
      navigate(`/location/${id}`);
      // navigate(`/${currentLocation.id}`);
      // } else if (newRoute === 'cameras' && !isCameras) {
      //   navigate('/cameras');
    } else if (newRoute === 'areas' && !isAreas) {
      // navigate('/areas');
      const id = currentLocation.sk.replace('location#', '');
      navigate(`/location/${id}/areas`);
    }
  }

  async function chooseLocation(l) {
    // console.log(l.sk);
    const id = l.sk.replace('location#', '');
    // console.log(id);
    // load location
    // const locationObj = await loadLocation(l);
    loadLocation(l);

    // setCurrentLocation(locationObj.location);
    // change to page
    navigate(`/location/${id}`);
  }

  return (
    <>
      <br></br>
      {/* <div style={{ padding: '10px' }}>
        <LocationSelect />
      </div>
      <br></br> */}

      {page === 'account' ? (
        <>
          <MenuItem
            style={{ color: blue }}
            // onClick={() => changeTo('dashboard')}
          >
            All locations
          </MenuItem>
          {account?.location_names?.map((l) => {
            return (
              <MenuItem
                key={l.sk}
                // style={{ color: isDashboard ? blue : 'black' }}
                // onClick={() => navigate(`/location/${id}`)}
                onClick={() => chooseLocation(l)}
              >
                {l.name}
              </MenuItem>
            );
          })}
        </>
      ) : (
        <>
          <MenuItem
            // style={{ color: isDashboard ? blue : 'black' }}
            onClick={() => navigate(`/`)}
          >
            {'< Back to all locations'}
          </MenuItem>
          <MenuItem
            style={{ color: isDashboard ? blue : 'black' }}
            onClick={() => changeTo('dashboard')}
          >
            Dashboard
          </MenuItem>
          <MenuItem
            style={{ color: isAreas ? blue : 'black' }}
            onClick={() => changeTo('areas')}
          >
            Areas
          </MenuItem>
          {/* <MenuItem
            style={{ color: isCameras ? blue : 'black' }}
            onClick={() => changeTo('cameras')}
          >
            Cameras
          </MenuItem> */}
        </>
      )}

      {/* <div>
        <button onClick={() => navigate('/dashboard')}>dashboard</button>
      </div>
      <div>
        <button onClick={() => navigate('/data')}>data</button>
      </div> */}
    </>
  );
}

export default Sidebar;
