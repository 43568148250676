export default function Footer() {
  return (
    <>
      <div
        style={{
          backgroundColor: '#222222',
          // backgroundColor: 'blue',
          padding: '40px',
          color: 'white',
          textAlign: 'center',
          // fontSize: '24px',
        }}
      >
        <div>© 2023 Ambul, Inc.</div>
      </div>
    </>
  );
}
