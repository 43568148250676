import React from 'react';
import axios from 'axios';
// import { useNavigate } from 'react-router-dom';
import {
  signOut,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  onAuthStateChanged,
} from 'firebase/auth';
import {
  getDocs,
  getDoc,
  doc,
  collection,
  addDoc,
  // setDoc,
  // onSnapshot,
  query,
  limit,
  // exists,
  // docs,
  updateDoc,
  arrayUnion,
} from 'firebase/firestore';
import { backendUrl } from 'helpers/constants';
import { useRecoilValue, useRecoilState, useSetRecoilState } from 'recoil';
import * as atoms from 'recoil/atoms';

import { db, auth } from 'helpers/initializeFirebase';

const headers = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
};

export function useCameraData() {
  // const navigate = useNavigate();

  const setTotalUnique = useSetRecoilState(atoms.totalUnique);

  const [account, setAccount] = useRecoilState(atoms.account);
  const [currentUser, setUser] = useRecoilState(atoms.user);
  const [currentLocation, setCurrentLocation] = useRecoilState(
    atoms.currentLocation,
  );
  const setLocations = useSetRecoilState(atoms.locations);
  const [currentCamera, setCurrentCamera] = useRecoilState(atoms.currentCamera);

  const [users, setUsers] = useRecoilState(atoms.users);
  const [trackingData, setTrackingData] = useRecoilState(atoms.trackingData);
  const [processedData, setProcessedData] = useRecoilState(atoms.processedData);
  const [ogProcessedData, setOGProcessedData] = useRecoilState(
    atoms.processedOGData,
  );

  // const res = await axios.get('https://httpbin.org/get', { params: { answer: 42 } });
  // res.data.args;

  async function loadTotalUnique() {
    const response = await axios({
      method: 'POST',
      url: `${backendUrl}/totalUnique`,
      headers,
      data: {
        test: 'working?',
      },
    });
    const data = response.data;
    return data;
  }

  async function loadLocationStats(params) {
    const response = await axios({
      method: 'POST',
      url: `${backendUrl}/locationStats`,
      headers,
      data: params,
    });
    return response.data;
  }

  async function fetchHeatmapData(params) {
    const response = await axios({
      method: 'POST',
      url: `${backendUrl}/heatmap`,
      headers,
      data: params,
    });
    // const data = JSON.parse(response.data);
    const data = response.data;
    // console.log('TEST AXIOS', data);
    return data;
  }

  async function fetchUserIds(params) {
    const response = await axios({
      method: 'POST',
      url: `${backendUrl}/userIds`,
      headers,
      data: params,
    });
    // const data = JSON.parse(response.data);
    const data = response.data;
    // console.log('TEST AXIOS', data);
    return data;
  }

  async function logger(params) {
    console.log(params);
    await axios({
      method: 'POST',
      url: `${backendUrl}/log`,
      headers,
      data: params,
    });
    return;
  }

  return {
    loadLocationStats,
    loadTotalUnique,
    fetchHeatmapData,
    fetchUserIds,
    logger,
  };
}
