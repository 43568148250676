import React from 'react';
import DashboardLayout from 'components/helpers/DashboardLayout';
import { useLocation } from 'react-router-dom';
import LocationPrompt from 'components/main/LocationPrompt';
import LocationTilesContainer from 'components/main/LocationTilesContainer';
import { useRecoilValue } from 'recoil';
import * as atoms from 'recoil/atoms';
import { useDynamo } from 'hooks/use-dynamo';
// import { useData } from 'hooks/use-data';
// import AddNewCamera from 'components/main/AddNewCamera';
// import AddNewArea from 'components/main/AddNewArea';
// import Card from '@mui/material/Card';
// import Box from '@mui/material/Box';
// import Grid from '@mui/material/Grid';
// import { CardActionArea, CardMedia, CardContent } from '@mui/material';
// import defaultCamera from 'assets/default_camera_3.png';
// import LocationHeatmap from 'components/main/LocationHeatmap';
// import LocationStats from 'components/main/LocationStats';
// import LocationDashboard from 'components/main/LocationDashboard';
// import LocationAreas from 'components/main/LocationAreas';
// import LocationCameras from 'components/main/LocationCameras';

export default function LocationPage() {
  const { loadLocation } = useDynamo();
  const account = useRecoilValue(atoms.account);
  const location = useLocation();

  React.useEffect(() => {
    const p = location.pathname.replace('/location/', '');
    if (p.length > 0) {
      const obj = {
        pk: account.pk,
        sk: `location#${p}`,
      };
      // console.log('LocationPage', obj);
      loadLocation(obj);
    }
  }, []);

  return (
    <DashboardLayout>
      <LocationPrompt />
      <LocationTilesContainer />
      {/* <div style={{ padding: '20px' }}>
        <h3>{currentLocation.name}</h3>
      </div> */}
      {/* 
      <div
        style={{ width: '100%', maxWidth: '1200px', border: 'solid blue 2px' }}
      >
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <div style={{ border: 'solid red 1px' }}>4</div>
          </Grid>
          <Grid item xs={4}>
            <div style={{ border: 'solid red 1px' }}>4</div>
          </Grid>
          <Grid item xs={4}>
            <div style={{ border: 'solid red 1px' }}>4</div>
          </Grid>

          <Grid item xs={12}>
            <div style={{ border: 'solid red 1px' }}>12</div>
          </Grid>

          <Grid item xs={10}>
            <div style={{ border: 'solid red 1px' }}>10</div>
          </Grid>
          <Grid item xs={2}>
            <div style={{ border: 'solid red 1px' }}>2</div>
          </Grid>

          <Grid item xs={2}>
            <div style={{ border: 'solid red 1px' }}>2</div>
          </Grid>
          <Grid item xs={2}>
            <div style={{ border: 'solid red 1px' }}>2</div>
          </Grid>
          <Grid item xs={2}>
            <div style={{ border: 'solid red 1px' }}>2</div>
          </Grid>
          <Grid item xs={2}>
            <div style={{ border: 'solid red 1px' }}>2</div>
          </Grid>
          <Grid item xs={2}>
            <div style={{ border: 'solid red 1px' }}>2</div>
          </Grid>
          <Grid item xs={2}>
            <div style={{ border: 'solid red 1px' }}>2</div>
          </Grid>

          <Grid item xs={3}>
            <div style={{ border: 'solid red 1px' }}>3</div>
          </Grid>
          <Grid item xs={3}>
            <div style={{ border: 'solid red 1px' }}>3</div>
          </Grid>
          <Grid item xs={3}>
            <div style={{ border: 'solid red 1px' }}>3</div>
          </Grid>
          <Grid item xs={3}>
            <div style={{ border: 'solid red 1px' }}>3</div>
          </Grid>

          <Grid item md={1}>
            <div style={{ border: 'solid red 1px' }}>md=1</div>
          </Grid>
          <Grid item md={1}>
            <div style={{ border: 'solid red 1px' }}>md=1</div>
          </Grid>
          <Grid item md={1}>
            <div style={{ border: 'solid red 1px' }}>md=1</div>
          </Grid>
        </Grid>
      </div>
       */}

      {/* {isDashboard && <LocationDashboard />}
      {isAreas && <LocationAreas />}
      {isCameras && <LocationCameras />} */}
      {/* <div style={{ padding: '20px', paddingTop: '0px' }}>
        <br></br>
        <br></br>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
          }}
        >
          <div>
            <h3>{currentLocation.name}</h3>
            <div>{currentLocation.other}</div>
          </div>
          
          {currentLocation.image_url && <AddNewArea />}
        </div>
        <br></br>
        <LocationStats />
        <br></br>
        <br></br>
        {!currentLocation?.camera_names && (
          <div style={{ textAlign: 'center' }}>
            <br></br>
            <br></br>
            <br></br>
            <div>No cameras yet. Click below to add one.</div>
            <AddNewCamera />
          </div>
        )}
        {currentLocation.camera_names && <LocationHeatmap />}

        
        <br></br>
        <br></br>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
          }}
        >
          <div>
            <h3>Cameras</h3>
          </div>
          {currentLocation?.camera_names && <AddNewCamera />}
        </div>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
          }}
        >
          {currentLocation?.camera_names?.map((camera, index) => {
            return (
              <Card
                key={camera.id}
                variant="outlined"
                sx={{
                  maxWidth: 220,
                  width: '100%',
                  margin: '10px',
                  marginLeft: '0px',
                  marginRight: '20px',
                }}
              >
                <CardActionArea onClick={() => onSelectCamera(camera)}>
                  <CardMedia
                    component="img"
                    height="125"
                    image={
                      camera.thumbnail_url
                        ? camera.thumbnail_url
                        : defaultCamera
                      // : 'https://firebasestorage.googleapis.com/v0/b/ambul-90ef4.appspot.com/o/street.png?alt=media&token=1a1066c0-45a6-4945-80c1-98b3ff1021f2'
                    }
                    // image="https://firebasestorage.googleapis.com/v0/b/ambul-90ef4.appspot.com/o/street.png?alt=media&token=1a1066c0-45a6-4945-80c1-98b3ff1021f2"
                    alt="default"
                  />
                  <CardContent>
                    <div style={{ fontWeight: 'bold' }}>{camera.name}</div>
                  </CardContent>

                  
                </CardActionArea>
              </Card>
            );
          })}
        </Box>
        <br></br>
        <br></br>
        
        <br></br>
        
      </div> */}
    </DashboardLayout>
  );
}
