import React from 'react';
import AddNewTileLocation from 'components/main/AddNewTileLocation';
import { useRecoilValue } from 'recoil';
import * as atoms from 'recoil/atoms';

export default function LocationTilesContainer() {
  const currentLocation = useRecoilValue(atoms.currentLocation);
  const locationLoading = useRecoilValue(atoms.locationLoading);
  const locationError = useRecoilValue(atoms.locationError);

  return (
    <div style={{ padding: '20px', paddingTop: '0px' }}>
      <br></br>
      <br></br>
      {locationLoading && <div>Loading...</div>}
      {locationError && <div>Error loading location. Please refresh.</div>}
      {(!currentLocation?.tiles || currentLocation?.tiles.length == 0) && (
        <>
          <div>
            <h3>{currentLocation.name}</h3>
          </div>
          <div style={{ textAlign: 'center' }}>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <div>No tiles yet. Click below to add one.</div>
            <AddNewTileLocation />
          </div>
        </>
      )}
      {currentLocation?.tiles && currentLocation?.tiles?.length !== 0 && (
        <div className="space_between">
          <div>
            <h3>{currentLocation.name}</h3>
          </div>
          <div style={{ textAlign: 'right' }}>
            <AddNewTileLocation />
          </div>
        </div>
      )}
      <br></br>
      {currentLocation?.tiles?.map((t) => {
        return (
          <div key={t.id}>
            {t.name} {t.id}
          </div>
        );
      })}
    </div>
  );
}
