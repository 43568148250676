// import { useNavigate } from 'react-router-dom';
import { useRecoilValue, useRecoilState, useSetRecoilState } from 'recoil';
import * as atoms from 'recoil/atoms';
import { parseURL } from 'helpers/helpers';
import {
  getUserByEmail,
  getAccountById,
  getLocationById,
  getCameraById,
  createCamera,
  createVideo,
  createBetaUser,
  updateLocation,
} from 'helpers/firebaseHelper';

export function useData() {
  // const navigate = useNavigate();
  const [locationIdFromUrl, cameraIdFromUrl, groupIdFromUrl] = parseURL();
  // console.log({ locationIdFromUrl, cameraIdFromUrl, groupIdFromUrl });

  const [account, setAccount] = useRecoilState(atoms.account);
  const [currentUser, setUser] = useRecoilState(atoms.user);
  const [currentLocation, setCurrentLocation] = useRecoilState(
    atoms.currentLocation,
  );
  const [currentCamera, setCurrentCamera] = useRecoilState(atoms.currentCamera);
  const setAuthChecked = useSetRecoilState(atoms.authChecked);

  async function initialLoad(authedUser) {
    console.log('INITIAL LOAD');
    const userInfo = await getUserByEmail(authedUser.email);
    const accountInfo = await getAccountById(userInfo.accountId);
    // console.log({ userInfo });
    // console.log({ accountInfo });

    // UNCOMMENT THIS

    const FAKE_DATA_SKIP_LOAD = false;

    if (!FAKE_DATA_SKIP_LOAD && accountInfo?.location_names?.length > 0) {
      if (locationIdFromUrl) {
        const locationInfo = await getLocationById(
          userInfo.accountId,
          locationIdFromUrl,
        );
        // console.log({ locationInfo });
        setCurrentLocation(locationInfo);
        if (cameraIdFromUrl) {
          const cameraInfo = await getCameraById(
            userInfo.accountId,
            locationIdFromUrl,
            cameraIdFromUrl,
          );
          setCurrentCamera(cameraInfo);
          //   navigate(`${currentLocation.id}/camera/${camera.id}`);
        }
        // if(groupIdFromUrl)
      } else {
        const locationName = accountInfo.location_names[0];
        const locationInfo = await getLocationById(
          userInfo.accountId,
          locationName.id,
        );
        setCurrentLocation(locationInfo);
        window.history.replaceState(null, null, `/${locationName.id}`);
      }
    }

    setUser(userInfo);
    setAccount(accountInfo);
    setAuthChecked(true);
  }

  async function onSelectLocation(locationId) {
    const locationInfo = await getLocationById(account.id, locationId);
    setCurrentLocation(locationInfo);
    // navigate(`/${locationId}`);
  }

  const onSelectCamera = async (camera) => {
    const cameraInfo = await getCameraById(
      account.id,
      currentLocation.id,
      camera.id,
    );
    setCurrentCamera(cameraInfo);
    // navigate(`/${currentLocation.id}/camera/${camera.id}`);
  };

  const onNewCamera = async (name) => {
    const updatedLocation = await createCamera(
      account.id,
      currentLocation,
      name,
    );
    setCurrentLocation(updatedLocation);
  };

  const onNewVideo = async (name) => {
    const updatedCamera = await createVideo(
      account.id,
      currentLocation.id,
      currentCamera,
      name,
    );
    setCurrentCamera(updatedCamera);
  };

  const onJoinBeta = async (email) => {
    await createBetaUser(email);
  };

  const saveLocationAreas = async (areas, canvasHeight, canvasWidth) => {
    const { height, width } = currentLocation;
    const formattedAreas = areas.map((area) => {
      const { index, name, point1, point2, locked = false } = area;
      return {
        index,
        name,
        locked,
        min_x: parseInt((Math.min(point1.x, point2.x) * width) / canvasWidth),
        max_x: parseInt((Math.max(point1.x, point2.x) * width) / canvasWidth),
        min_y: parseInt((Math.min(point1.y, point2.y) * height) / canvasHeight),
        max_y: parseInt((Math.max(point1.y, point2.y) * height) / canvasHeight),
      };
    });
    updateLocation(account.id, currentLocation.id, formattedAreas);
    setCurrentLocation({
      ...currentLocation,
      areas: formattedAreas,
    });
  };

  return {
    initialLoad,
    onNewCamera,
    onNewVideo,
    onSelectCamera,
    onSelectLocation,
    onJoinBeta,
    saveLocationAreas,
  };
}
