import React from 'react';
import AddNewCamera from 'components/main/AddNewCamera';
import AddNewTileAccount from 'components/main/AddNewTileAccount';
import TileBase from 'components/main/TileBase';
import { useRecoilValue } from 'recoil';
import * as atoms from 'recoil/atoms';

export default function AccountTilesContainer() {
  const currentAccount = useRecoilValue(atoms.account);
  const accountLoading = useRecoilValue(atoms.accountLoading);

  if (accountLoading) return <div>Loading...</div>;

  return (
    <div style={{ padding: '20px', paddingTop: '0px' }}>
      <br></br>
      <br></br>
      {(!currentAccount?.tiles || currentAccount?.tiles.length == 0) && (
        <div style={{ textAlign: 'center' }}>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <div>No tiles yet. Click below to add one.</div>
          <AddNewTileAccount />
        </div>
      )}
      {/* {currentAccount?.tiles && currentAccount?.tiles.length !== 0 && (
        <div style={{ textAlign: 'right' }}>
          <AddNewTileAccount />
        </div>
      )} */}
      <div
        style={{
          // display: 'flex',
          // flexWrap: 'wrap',
          display: 'grid',

          gridTemplateColumns: 'repeat(auto-fill, minmax(250px, 1fr))',
          gap: '20px',
        }}
      >
        {currentAccount?.tiles?.map((t) => {
          return <TileBase key={t.id} tile={t} />;
        })}
      </div>
    </div>
  );
}
