export default function JoinBeta() {
  return (
    <>
      <div
        style={{
          backgroundColor: '#222222',
          // backgroundColor: 'blue',
          padding: '40px',
          color: 'white',
          textAlign: 'center',
          fontSize: '18px',
        }}
      >
        <div
          style={{
            maxWidth: '1400px',
            margin: 'auto',
            // padding: '15px 0px',
          }}
        >
          {/* <h3>What do we do</h3>
          <br></br>
          <h5>
            Ambul helps stores collect data on their customers as they travel
            thoughtout the store.
          </h5> */}
          {/* <br></br>
          <br></br>
          <br></br> */}
          {/* <h5
          // style={{
          //   textAlign: 'left',
          // }}
          >
            Answer questions like..
          </h5> */}
          <br></br>
          <div
            className="break_flex"
            style={{
              textAlign: 'left',
              // display: 'flex',
              // gap: '50px',
              // width: '100%',
              // flexWrap: 'wrap',
            }}
          >
            <div style={{ width: '100%', padding: '10px 0px' }}>
              <h2 className="highlight_text_beta">What do we do</h2>
              {/* <h3>What do we do</h3> */}
              <br></br>
              <h5>
                Ambul helps stores collect data on their customers as they
                travel throughout the store.
              </h5>
              {/* <ul>
                <li>How many customers pass by the deli?</li>
                <li>What's the average wait time at checkout?</li>
                <li>How many people leave without buying something?</li>
                <li>What do the non-buyers do in my store?</li>
              </ul> */}
            </div>
            <div style={{ width: '100%', padding: '10px 0px' }}>
              <div>We help answer questions like..</div>
              <ul className="questions_like">
                <li>How many people leave without buying something?</li>
                <li>What do the non-buyers do in my store?</li>
                <li>What's the average wait time at checkout?</li>
                <li>How many customers pass by the deli?</li>
                <li>How effective is our new display?</li>
                <li>What are our customers struggling to find?</li>
                <li>
                  What is the total number of customers in and out each day?
                </li>
                <li>What paths do my customers travel?</li>
              </ul>
              {/* <div>How many customers pass by the deli?</div>
              <div>What's the average wait time at checkout?</div>
              <div>How many people leave without buying something?</div>
              <div>What do the non-buyers do in my store?</div>
              <div>How effective is our new display?</div>
              <div>What are our customers struggling to find?</div>
              <div>
                What is the total number of customers in and out each day?
              </div>
              <div>What paths do my customers travel?</div> */}
            </div>
          </div>
          {/* <div>Learn where your customer is struggling</div>
          <div>Learn how to improve their shopping experience</div>
          <div>Measure ineffiencies in store layout</div>
          <br></br> */}
          {/* <div>Want to know how many customers pass by the deli?</div>
          <div>Want to know the average wait time at checkout?</div>
          <div>Want to know the effectiveness of a new display?</div>
          <div>Want to know the how many people pass by each product?</div>
          <div>Want to know what your customer is struggling to find?</div>
          <div>Want to know total number of customers in and out each day?</div>
          <div>Want to know what percentage of customers buy something?</div>
          <div>Want to know the path customers travel? </div> */}
          {/* <br></br>
          <div>
            Our goal is to help you create a better experience so your customers
            are more loyal and spend more.
          </div>
          <div>Our goal is to help you understand your customers better.</div> */}
        </div>
      </div>
    </>
  );
}
