import { atom } from 'recoil';

export const authChecked = atom({
  key: 'authChecked',
  default: false,
});
export const user = atom({
  key: 'user',
  default: {},
});
export const account = atom({
  key: 'account',
  default: {},
});
export const locations = atom({
  key: 'locations',
  default: [],
});
export const currentLocation = atom({
  key: 'currentLocation',
  default: {},
});
export const currentCamera = atom({
  key: 'currentCamera',
  default: {},
});
export const totalUnique = atom({
  key: 'totalUnique',
  default: 0,
});
export const mapOptions = atom({
  key: 'mapOptions',
  default: {},
});

// sign in
export const signInEmail = atom({
  key: 'signInEmail', // unique ID (with respect to other atoms/selectors)
  default: '', // default value (aka initial value)
});
export const signInPassword = atom({
  key: 'signInPassword',
  default: '',
});
export const signInError = atom({
  key: 'signInError',
  default: '',
});

// sign up
export const signUpEmail = atom({
  key: 'signUpEmail',
  default: '',
});
export const signUpPassword = atom({
  key: 'signUpPassword',
  default: '',
});
export const signUpError = atom({
  key: 'signUpError',
  default: '',
});

// forgot password
export const forgotPasswordEmail = atom({
  key: 'forgotPasswordEmail',
  default: '',
});
export const forgotPasswordError = atom({
  key: 'forgotPasswordError',
  default: '',
});

// users
export const users = atom({
  key: 'users',
  default: [],
});
// trackingData
export const trackingData = atom({
  key: 'trackingData',
  default: {},
});
export const processedData = atom({
  key: 'processedData',
  default: [],
});
export const processedOGData = atom({
  key: 'processedOGData',
  default: [],
});

// loading
export const accountLoading = atom({
  key: 'accountLoading',
  default: false,
});
export const locationLoading = atom({
  key: 'locationLoading',
  default: false,
});

// error
export const locationError = atom({
  key: 'locationError',
  default: false,
});
