import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from 'hooks/use-auth';
import { useRecoilValue } from 'recoil';
import Button from '@mui/material/Button';
import MenuIcon from '@mui/icons-material/Menu';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
// import LocationSelect from 'components/main/LocationSelect';
// import Paper from '@mui/material/Paper';
// import TextField from '@mui/material/TextField';
// import FormControl from '@mui/material/FormControl';
import * as atoms from 'recoil/atoms';

// import undraw_prototype from 'assets/undraw_prototype.svg';

function Header() {
  const [dropdownOpen, setDropdownOpen] = React.useState(false);
  const account = useRecoilValue(atoms.account);
  const user = useRecoilValue(atoms.user);
  const { onSignOut } = useAuth();
  const navigate = useNavigate();

  return (
    <div>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: '1fr 1fr 1fr',

          // display: 'flex',
          // flexDirection: 'row',
          // justifyContent: 'space-between',
          alignItems: 'center',
          // backgroundColor: '#191919', // darker black
          // backgroundColor: '#1A1B25', // stripe blueish black
          // backgroundColor: '#0036F9', // blue
          // backgroundColor: '#2a2a2a',
          // backgroundColor: '#0c0c0c',
          // color: 'white',
          padding: '10px 0px',
          // borderBottom: 'solid 2px #0036F9',
          borderBottom: 'solid 1px rgba(0,0,0,.52)',
        }}
      >
        <h3 style={{ marginLeft: '50px' }} className={'logo'}>
          Ambul
        </h3>
        <Box
          sx={{
            textAlign: 'center',
            // backgroundColor: 'red',
          }}
        >
          <div>
            <b>{account.name}</b>
          </div>
          <div>{user.email}</div>
        </Box>
        <div
          style={{
            textAlign: 'right',
            // backgroundColor: 'blue',
            alignSelf: 'center',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'right',
          }}
        >
          {/* <div
            style={{
              width: '100',
              maxWidth: '150px',
              // marginLeft: 'auto',
              // backgroundColor: 'orange',
            }}
          >
            <LocationSelect />
          </div> */}
          <div
            style={{
              width: 100,
              // backgroundColor: 'pink'
            }}
          >
            <MenuIcon
              sx={{
                marginRight: '50px',
              }}
              onClick={() => setDropdownOpen((prev) => !prev)}
            />
          </div>
        </div>
      </Box>
      {dropdownOpen && (
        <div
          style={{
            // backgroundColor: 'rgba(0,0,0,.3)',
            height: '100vh',
            width: '100vw',
            position: 'absolute',
            top: 0,
            left: 0,
            overflow: 'hidden',
          }}
          onClick={() => setDropdownOpen((prev) => !prev)}
        >
          <Card
            variant="outlined"
            sx={{
              position: 'absolute',
              right: '10px',
              zIndex: '2',
              top: '60px',
            }}
            // onClick={(event) => event.stopPropagation()}
          >
            {/* <div>
              <Button onClick={() => navigate('/signin')}>Sign In</Button>
            </div>
            <div>
              <Button onClick={() => navigate('/signup')}>Sign Up</Button>
            </div> */}
            <div>
              <Button onClick={onSignOut}>Sign out</Button>
            </div>
          </Card>
        </div>
      )}
    </div>
  );
}

export default Header;
