import { createTheme } from '@mui/material/styles';

// off black font 222222
// light gray F7F7F7

export const theme = createTheme({
  palette: {
    primary: {
      // main: '#0B42FF',
      // main: '#3374FF',
      // main: '#0036F9',
      main: '#0852FD',
    },
    secondary: {
      // main: green[500],
      main: '#05BDFF',
    },
  },
  typography: {
    button: {
      textTransform: 'none',
      fontSize: '16px',
      fontFamily: '"Roboto", sans-serif',
      fontWeight: '400',
      padding: '8px 24px',
      paddingTop: '8px',
      paddingBottom: '8px',
      paddingRight: '24px',
      paddingLeft: '24px',
      borderRadius: '6px',
      borderTopLeftRadius: '6px',
      borderTopRightRadius: '6px',
      borderBottomLeftRadius: '6px',
      borderBottomRightRadius: '6px',
    },
    input: {
      // textTransform: 'none',
      // fontSize: '16px',
      // fontFamily: '"Roboto", sans-serif',
      // fontWeight: '400',
      padding: '10px 24px',
      paddingTop: '10px',
      paddingBottom: '10px',
      paddingRight: '24px',
      paddingLeft: '24px',
      borderRadius: '8px',
      // borderTopLeftRadius: '18px',
      // borderTopRightRadius: '28px',
      // borderBottomLeftRadius: '8px',
      // borderBottomRightRadius: '8px',
      // borderColor: 'rgba(0, 0, 0, 0.32)',
      // borderColor: 'orange',
    },
  },
  components: {
    // MuiFormControl: {
    //   styleOverrides: {
    //     root: {
    //       backgroundColor: 'red',
    //       // borderColor: 'rgba(0, 0, 0, 0.32)',
    //       borderRadius: '28px',
    //       borderTopLeftRadius: '28px',
    //       borderTopRightRadius: '28px',
    //       borderBottomLeftRadius: '8px',
    //       borderBottomRightRadius: '8px',
    //     },
    //   },
    // },

    MuiSelect: {
      styleOverrides: {
        root: {
          // paddingTop: '4px',
          // paddingBottom: '4px',
          // backgroundColor: 'blue',
          border: '1px solid blue',
          // borderColor: 'blue',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          // paddingTop: '4px',
          // paddingBottom: '4px',
          // backgroundColor: 'blue',
          // border: '1px solid blue',
          // borderColor: 'blue',
        },
      },
    },
    MuiSelectBase: {
      styleOverrides: {
        root: {
          // paddingTop: '4px',
          // paddingBottom: '4px',
          // borderColor: 'red',
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          // paddingTop: '4px',
          // paddingBottom: '4px',
          // borderColor: 'red',
          // backgroundColor: 'blue',
        },
      },
    },
    // MuiFormLabel-root-MuiInputLabel-root.Mui-focused
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          // backgroundColor: 'red',
          // borderColor: 'rgba(0, 0, 0, 0.32)',
          // borderRadius: '28px',
          // padding: '10px 24px',
          // paddingTop: '4px',
          // paddingBottom: '4px',
          // paddingRight: '24px',
          // paddingLeft: '24px',
          borderTopLeftRadius: '6px',
          borderTopRightRadius: '6px',
          borderBottomLeftRadius: '6px',
          borderBottomRightRadius: '6px',
          // borderColor: 'rgba(0, 0, 0, 0.52)',
          // borderColor: 'green',
          // border: 'solid 2px orange',
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          // backgroundColor: 'red',
          borderColor: 'rgba(0, 0, 0, 0.52)',
          // borderColor: 'rgba(0, 0, 0, 0.42)',
        },
      },
      variants: [
        {
          props: { variant: 'dashed' },
          style: {
            textTransform: 'none',
            border: `1px dashed rgba(0, 0, 0, 0.52)`,
            // border: `1px dashed rgba(0, 0, 0, 0.42)`,
          },
        },
      ],
    },
    MuiButton: {
      styleOverrides: {
        root: {
          // backgroundColor: 'red',
          // borderColor: 'rgba(0, 0, 0, 0.32)',
        },
      },
      variants: [
        {
          props: { variant: 'black_outlined' },
          style: {
            color: 'black',
            border: 'solid 1px black',
            // borderColor: 'rgba(0, 0, 0, 0.42)',
            borderColor: 'rgba(0, 0, 0, 0.52)',
            // textTransform: 'none',
            // border: `2px dashed blue`,
          },
        },
        {
          props: { variant: 'black_solid' },
          style: {
            color: 'white',
            backgroundColor: 'black',
            // textTransform: 'none',
            // border: `2px dashed blue`,
            '&:hover': {
              backgroundColor: 'rgba(0, 0, 0, 0.8)',
            },
          },
        },
        {
          props: { variant: 'white' },
          style: {
            color: 'white',
            borderColor: 'black',
            // textTransform: 'none',
            // border: `2px dashed blue`,
            '&:hover': {
              backgroundColor: 'rgba(255, 255, 255, 0.2)',
            },
          },
        },
        {
          props: { variant: 'outlined' },
          style: {
            // backgroundColor: 'white',
            backgroundColor: '#f9f9f9',
            // color: 'white',
            // borderColor: 'black',
            // textTransform: 'none',
            // border: `2px dashed blue`,
            '&:hover': {
              backgroundColor: 'rgba(255, 255, 255, 0.95)',
            },
          },
        },
        {
          props: { size: 'small' },
          style: {
            // backgroundColor: 'orange',
            paddingTop: '8.5px',
            paddingBottom: '8.5px',
            // color: 'white',
            // borderColor: 'black',
            // textTransform: 'none',
            // border: `2px dashed blue`,
            // '&:hover': {
            //   backgroundColor: 'rgba(255, 255, 255, 0.2)',
            // },
          },
        },
        {
          props: { size: 'large' },
          style: {
            // backgroundColor: 'orange',
            paddingTop: '14.5px',
            paddingBottom: '14.5px',
            fontSize: '16px',
            // color: 'white',
            // borderColor: 'black',
            // textTransform: 'none',
            // border: `2px dashed blue`,
            // '&:hover': {
            //   backgroundColor: 'rgba(255, 255, 255, 0.2)',
            // },
          },
        },
      ],
    },
  },
});
