import { getAuth } from 'firebase/auth';
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: 'AIzaSyBijG6D7RGZAZmrF_HhfcoOVE7XxOimPe8',
  authDomain: 'ambul-90ef4.firebaseapp.com',
  projectId: 'ambul-90ef4',
  storageBucket: 'ambul-90ef4.appspot.com',
  messagingSenderId: '225155337985',
  appId: '1:225155337985:web:0c69bcb36ad299483dd500',
};

initializeApp(firebaseConfig);

export const auth = getAuth();
export const db = getFirestore();
