import React from 'react';
import Button from '@mui/material/Button';

export default function Popover({ open, onClose, children }) {
  React.useEffect(() => {
    if (open) {
      document.getElementById('root').style.overflow = 'hidden';
    }
    return () => (document.getElementById('root').style.overflow = 'scroll');
  }, [open]);

  return (
    <>
      {open && (
        <div
          style={{
            backgroundColor: 'white',
            position: 'fixed',
            top: 0,
            left: 0,
            zIndex: 100,
            overflow: 'auto',
            width: '100vw',
            height: '100vh',
          }}
        >
          <br></br>
          <div style={{ padding: '20px' }}>
            <Button variant="outlined" onClick={() => onClose()}>
              Close
            </Button>
          </div>

          <div style={{ padding: '20px' }}>{children}</div>
        </div>
      )}
    </>
  );
}
