import React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import { useData } from 'hooks/use-data';

export default function JoinBeta({ refProp }) {
  const [email, setEmail] = React.useState('');
  const [submitted, setSubmitted] = React.useState(false);
  const { onJoinBeta } = useData();

  function onEmailChange(event) {
    setEmail(event.target.value);
  }

  function submit(event) {
    event.preventDefault();
    // console.log(email);
    setSubmitted(true);
    onJoinBeta(email);
    setEmail('');
  }

  return (
    <>
      <div
        ref={refProp}
        style={{
          backgroundColor: '#0852FD',
          // backgroundColor: '#222222',
          // backgroundColor: 'blue',
          padding: '40px',
          color: 'white',
          textAlign: 'center',
          fontSize: '24px',
        }}
      >
        {/* <h2>Join beta</h2>
        <br></br> */}
        <h2>
          Ambul <span className="highlight_text_beta">Beta</span> is live
        </h2>
        {/* <div>
          Right now we are collecting names of those interested in joining our
          beta version.
        </div> */}
        {/* <br></br> */}
        <div>Join the limited beta</div>
        <br></br>
        {/* <div>Interested in joinging the beta?</div> */}

        <form onSubmit={submit}>
          <FormControl>
            <TextField
              size="medium"
              label="Email"
              variant="outlined"
              value={email}
              type="text"
              onChange={onEmailChange}
              style={{
                backgroundColor: 'white',
                borderRadius: '8px',
                // maxWidth: '250px',
                marginRight: '10px',
              }}
            />
          </FormControl>

          <Button
            sx={{ width: 'fit-content' }}
            color="primary"
            variant="outlined"
            type="submit"
            size="large"
          >
            Join beta
          </Button>

          {/* <div
            style={{
              // display: 'flex',
              // flexWrap: 'wrap',
              margin: 'auto',
              backgroundColor: 'red',
              width: 'fit-content',
            }}
          > */}

          {/* <br></br>
          <br></br> */}

          {/* <br></br>
          <div style={{ color: 'red' }}>{error}</div>
          <br></br> */}
          {/* <div>
              
            </div>
          </div> */}
        </form>
        {submitted && (
          <>
            <br></br>
            <div>
              Thank you for your interest. We will reach out to you soon.
            </div>
          </>
        )}
      </div>
    </>
  );
}
