import React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import { useDynamo } from 'hooks/use-dynamo';

export default function AccountPrompt() {
  const { createAccoutTile } = useDynamo();
  const [prompt, setPrompt] = React.useState('');

  function submit(e) {
    e.preventDefault();
    console.log('submit');
    createAccoutTile({
      name: prompt,
      prompt,
      created: new Date().toISOString(),
    });
    setPrompt('');
  }

  function onPromptChange(e) {
    e.preventDefault();
    setPrompt(e.target.value);
  }

  return (
    <div>
      <form onSubmit={submit}>
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: 'auto 100px',
            gap: '10px',
          }}
        >
          <FormControl>
            <TextField
              style={{
                backgroundColor: 'white',
                width: '100%',
                // maxWidth: '400px',
              }}
              size="small"
              // label="Email"
              variant="outlined"
              value={prompt}
              placeholder="What would you like to know?"
              type="text"
              onChange={onPromptChange}
            />
          </FormControl>
          <Button
            type="submit"
            color="primary"
            variant="contained"
            size="small"
          >
            Submit
          </Button>
        </div>
      </form>
    </div>
  );
}
