import React from 'react';
import { Auth, Hub } from 'aws-amplify';
import {
  signOut,
  onAuthStateChanged,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
} from 'firebase/auth';
import { useRecoilValue, useRecoilState, useSetRecoilState } from 'recoil';
import * as atoms from 'recoil/atoms';

import { auth } from 'helpers/initializeFirebase';
import { useData } from 'hooks/use-data';
import { useCameraData } from 'hooks/use-camera-data';
import { useDynamo } from 'hooks/use-dynamo';
// import { useNavigate } from 'react-router-dom';

export function useAuth() {
  // const navigate = useNavigate();
  const { loadAccount } = useDynamo();
  const { initialLoad } = useData();
  const { logger } = useCameraData();
  // sign in
  const signInEmail = useRecoilValue(atoms.signInEmail);
  const signInPassword = useRecoilValue(atoms.signInPassword);
  const [signInError, setSignInError] = useRecoilState(atoms.signInError);
  // sign up
  const signUpEmail = useRecoilValue(atoms.signUpEmail);
  const signUpPassword = useRecoilValue(atoms.signUpPassword);
  const [signUpError, setSignUpError] = useRecoilState(atoms.signUpError);
  // forgot password
  const forgotPasswordEmail = useRecoilValue(atoms.forgotPasswordEmail);
  const [forgotPasswordError, setForgotPasswordError] = useRecoilState(
    atoms.forgotPasswordError,
  );

  const setAccount = useSetRecoilState(atoms.account);
  const setUser = useSetRecoilState(atoms.user);
  const setAuthChecked = useSetRecoilState(atoms.authChecked);
  const currentUser = useRecoilValue(atoms.user);

  // React.useEffect(() => {
  //   if (!currentUser.name) {
  //     onAuthStateChanged(auth, async (user) => {
  //       console.log('>>>>> AUTH CHANGED');
  //       if (user) {
  //         initialLoad(user);

  //         // const userInfo = await loadUser(user.email);
  //         // setUser({
  //         //   ...userInfo,
  //         //   email: user.email,
  //         //   uid: user.uid,
  //         // });
  //         // console.log(history);
  //         // await loadAccountInitial(userInfo.accountId);

  //         if (window.location.hostname.includes('ambul')) {
  //           logger({
  //             action: 'auth',
  //             email: user.email,
  //             timestamp: new Date(),
  //           });
  //         }
  //       } else {
  //         console.log('NO USER FOUND');
  //         setAuthChecked(true);
  //       }
  //     });
  //   }
  // }, []);

  const listener = (data) => {
    switch (data.payload.event) {
      case 'configured':
        console.log('the Auth module is configured');
        break;
      case 'signIn':
        console.log('user signed in');
        break;
      case 'signIn_failure':
        console.log('ERROR: user sign in failed');
        break;
      case 'signUp':
        console.log('user signed up');
        break;
      case 'signUp_failure':
        console.log('ERROR: user sign up failed');
        break;
      case 'confirmSignUp':
        console.log('user confirmation successful');
        break;
      case 'completeNewPassword_failure':
        console.log('ERROR: user did not complete new password flow');
        break;
      case 'autoSignIn':
        console.log('auto sign in successful');
        break;
      case 'autoSignIn_failure':
        console.log('ERROR: auto sign in failed');
        break;
      case 'forgotPassword':
        console.log('password recovery initiated');
        break;
      case 'forgotPassword_failure':
        console.log('ERROR: password recovery failed');
        break;
      case 'forgotPasswordSubmit':
        console.log('password confirmation successful');
        break;
      case 'forgotPasswordSubmit_failure':
        console.log('ERROR: password confirmation failed');
        break;
      case 'tokenRefresh':
        console.log('token refresh succeeded');
        break;
      case 'tokenRefresh_failure':
        console.log('ERROR: token refresh failed');
        break;
      case 'cognitoHostedUI':
        console.log('Cognito Hosted UI sign in successful');
        break;
      case 'cognitoHostedUI_failure':
        console.log('ERROR: Cognito Hosted UI sign in failed');
        break;
      case 'customOAuthState':
        console.log('custom state returned from CognitoHosted UI');
        break;
      case 'customState_failure':
        console.log('ERROR: custom state failure');
        break;
      case 'parsingCallbackUrl':
        console.log('Cognito Hosted UI OAuth url parsing initiated');
        break;
      case 'userDeleted':
        console.log('user deletion successful');
        break;
      case 'signOut':
        console.log('user signed out');
        break;
    }
  };

  Hub.listen('auth', listener);

  async function checkAuth() {
    console.log('CHECK AUTH');
    try {
      const user = await Auth.currentAuthenticatedUser();

      if (user?.attributes?.email) {
        const userObj = {
          ...user.attributes,
          attributes: user.attributes,
          signInUserSession: user.signInUserSession,
        };
        // await initializeDynamo(userObj);
        setUser(userObj);
        // console.log('call load account');
        const accountObj = await loadAccount(userObj);
        // console.log('HERER', accountObj);
        accountObj?.account?.location_names?.forEach((l) =>
          console.log(l.name),
        );
        setAccount(accountObj?.account || {});
      }
      // setAuthChecked(true);
    } catch (error) {
      console.log('auth error:', error);
    } finally {
      setAuthChecked(true);
    }
  }

  const onSignIn = async () => {
    try {
      if (!signInEmail || !signInPassword) {
        setSignInError('Please include email and password');
        return;
      }

      const res = await Auth.signIn({
        username: signInEmail,
        password: signInPassword,
      });
      console.log(res);
      await checkAuth();

      // const res = await signInWithEmailAndPassword(
      //   auth,
      //   signInEmail,
      //   signInPassword,
      //   // 'nathanradmall+test1@gmail.com',
      //   // 'Natetest1!',
      // );
      // const user = res.user;
      // console.log(user);

      // const docKey = doc(db, 'users/nathanradmall+test1@gmail.com'); // collection/doc key
      // await setDoc(
      //   docKey,
      //   { name: 'Nathan Radmall Test 1' },
      //   { merge: true },
      // );

      // const usersCollection = collection(db, 'users');
      // const newDoc = await addDoc(usersCollection, {
      //   name: 'fake',
      //   customer: 'name',
      //   other: 'here',
      // });
      // console.log(newDoc);

      // navigate(`/`);
      // await collection('users').add({
      //   uid: user.uid,
      //   name: 'Nate test 1',
      //   authProvider: 'local',
      //   email,
      // });
      // await signInWithEmailAndPassword(auth, email, password);
    } catch (err) {
      //  console.error(err);
      // console.log(err.message);
      setSignInError('Email/password incorrect');
    }
  };

  const onSignUp = async () => {
    try {
      if (!signUpEmail || !signUpPassword) {
        setSignUpError('Please include email and password');
        return;
      }
      const res = await Auth.signUp({
        password: signUpPassword,
        username: signUpEmail,
      });
      console.log(res);
      // const res = await createUserWithEmailAndPassword(
      //   auth,
      //   signUpEmail,
      //   signUpPassword,
      // );
      // const user = res.user;
      // console.log(user);
      //     const user = res.user;
      //     console.log(user);
      //     await collection(db, 'users').add({
      //       uid: user.uid,
      //       name: 'Nate test 1',
      //       authProvider: 'local',
      //       email,
      //     });
    } catch (err) {
      console.error(err);
      // alert(err.message);
      setSignUpError('Sign up failed');
    }
  };

  const onForgotPassword = async () => {
    try {
      if (!forgotPasswordEmail) {
        setForgotPasswordError('Please add email');
        return;
      }

      const res = await sendPasswordResetEmail(auth, forgotPasswordEmail);
      alert('Password reset link sent!');
    } catch (err) {
      console.error(err);
      // alert(err.message);
      setForgotPasswordError('Something went wrong. Please try again');
    }
  };

  const onSignOut = () => {
    // navigate('/signin');
    signOut(auth);
    setUser({});
    setAccount({});
  };

  return {
    onSignIn,
    onSignUp,
    onForgotPassword,
    onSignOut,
    checkAuth,
  };
}
