import React from 'react';
import { v4 as uuidv4 } from 'uuid';
// import { Auth, Hub } from 'aws-amplify';
import { useRecoilValue, useRecoilState, useSetRecoilState } from 'recoil';
import * as atoms from 'recoil/atoms';

// import { auth } from 'helpers/initializeFirebase';
import { useData } from 'hooks/use-data';
import { useCameraData } from 'hooks/use-camera-data';

// import { DynamoDB } from '@aws-sdk/client-dynamodb';
// import { DynamoDBDocument } from '@aws-sdk/lib-dynamodb';
// import { fromCognitoIdentityPool } from '@aws-sdk/credential-providers';
// import { useNavigate } from 'react-router-dom';

// const TABLE_NAME = 'accounts';
// const BASE_URL = 'http://127.0.0.1:5000';
// const BASE_URL = 'http://localhost:8080';
const BASE_URL =
  'https://ecyf6p2gmj.execute-api.us-east-1.amazonaws.com/default/account';
// 'https://ecyf6p2gmj.execute-api.us-east-1.amazonaws.com/default/ambulNodeBackend';
// 'https://ecyf6p2gmj.execute-api.us-east-1.amazonaws.com/stage1/ambulNodeBackend';

export function useDynamo() {
  // const navigate = useNavigate();
  const { initialLoad } = useData();
  const { logger } = useCameraData();

  const setAccount = useSetRecoilState(atoms.account);
  const setAccountLoading = useSetRecoilState(atoms.accountLoading);
  const setUser = useSetRecoilState(atoms.user);
  const setAuthChecked = useSetRecoilState(atoms.authChecked);
  const currentUser = useRecoilValue(atoms.user);
  const account = useRecoilValue(atoms.account);
  const [currentLocation, setCurrentLocation] = useRecoilState(
    atoms.currentLocation,
  );
  const [locationLoading, setLocationLoading] = useRecoilState(
    atoms.locationLoading,
  );
  const [locationError, setLocationError] = useRecoilState(atoms.locationError);

  // const dbClient = React.useRef(null);
  // const db = React.useRef(null);

  async function loadAccount(userObj) {
    const { email } = userObj;
    try {
      const res = await fetch(BASE_URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          action: 'getAccountByEmail',
          email,
          jwt: userObj.signInUserSession.idToken.jwtToken,
        }),
      });
      const r = await res.json();
      // console.log(r);
      return r;
    } catch (error) {
      console.log('ERROR', error);
      return {};
    }
  }

  async function loadLocation(locationObj) {
    setLocationError(false);
    setCurrentLocation({});
    setLocationLoading(true);
    try {
      const res = await fetch(BASE_URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          ...locationObj,
          jwt: currentUser.signInUserSession.idToken.jwtToken,
          action: 'loadLocation',
        }),
      });
      const r = await res.json();
      // console.log(r);
      setCurrentLocation(r.location);
      return r;
    } catch (error) {
      console.log('ERROR', error);
      setLocationError(true);
    } finally {
      setLocationLoading(false);
    }
  }

  async function createAccoutTile(tileObj) {
    // const { email } = userObj;
    // console.log(account);
    const tempAccount = { ...account };
    const tiles = [...(account.tiles || [])];
    tiles.push({
      ...tileObj,
      id: uuidv4(),
    });
    tempAccount.tiles = tiles;
    setAccountLoading(true);
    const res = await fetch(BASE_URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        account: tempAccount,
        jwt: currentUser.signInUserSession.idToken.jwtToken,
        action: 'updateAccount',
      }),
    });
    const r = await res.json();
    // console.log(r);
    setAccount(tempAccount);
    setAccountLoading(false);
    return r;
  }

  async function createLocationTile(tileObj) {
    // const { email } = userObj;
    // console.log(currentLocation);
    const tempLocation = { ...currentLocation };
    const tiles = [...(currentLocation.tiles || [])];
    tiles.push({
      ...tileObj,
      id: uuidv4(),
    });
    tempLocation.tiles = tiles;
    const res = await fetch(BASE_URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        location: tempLocation,
        jwt: currentUser.signInUserSession.idToken.jwtToken,
        action: 'updateLocation',
      }),
    });
    const r = await res.json();
    // console.log(r);
    setCurrentLocation(tempLocation);
    return r;
  }

  return {
    // initializeDynamo,
    loadAccount,
    loadLocation,
    createAccoutTile,
    createLocationTile,
  };
}

// React.useEffect(() => {
//   // console.log('user changed');
//   dbClient.current = new DynamoDB({
//     region: 'us-east-1',
//     credentials: fromCognitoIdentityPool({
//       identityPoolId: 'us-east-1:1330dae5-f507-48e6-a6d6-90eb9fbf99b0',
//       clientConfig: { region: 'us-east-1' },
//       logins: {
//         'cognito-idp.us-east-1.amazonaws.com/us-east-1_SSQdWfivt':
//           currentUser.signInUserSession.idToken.jwtToken,
//       },
//     }),
//   });
//   db.current = DynamoDBDocument.from(dbClient.current);

//   return () => {
//     dbClient.current.destroy(); // no-op
//     db.current.destroy(); // destroys DynamoDBClient
//   };
// }, [currentUser]);

// async function initializeDynamo(userObj) {
//   console.log(userObj);
//   dbClient.current = new DynamoDB({
//     region: 'us-east-1',
//     credentials: fromCognitoIdentityPool({
//       // us-east-1:4e365458-bde1-4f82-834d-021208c7cfff", // Identity pool ID
//       identityPoolId: 'us-east-1:1330dae5-f507-48e6-a6d6-90eb9fbf99b0',
//       clientConfig: { region: 'us-east-1' },
//       logins: {
//         'cognito-idp.us-east-1.amazonaws.com/us-east-1_SSQdWfivt':
//           // us-east-1_y6j564Tqc
//           userObj.signInUserSession.idToken.jwtToken,
//       },
//     }),
//   });
//   db.current = DynamoDBDocument.from(dbClient.current);
// }
