import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useRecoilValue, useRecoilState, useSetRecoilState } from 'recoil';
import * as atoms from 'recoil/atoms';
import { useData } from 'hooks/use-data';
import { useCameraData } from 'hooks/use-camera-data';

// const BASE_URL = 'http://localhost:8080';
const BASE_URL =
  'https://ecyf6p2gmj.execute-api.us-east-1.amazonaws.com/default/snowflake';

export function useSnowflake() {
  // const navigate = useNavigate();
  const { initialLoad } = useData();
  const { logger } = useCameraData();

  const setAccount = useSetRecoilState(atoms.account);
  const setAccountLoading = useSetRecoilState(atoms.accountLoading);
  const setUser = useSetRecoilState(atoms.user);
  const setAuthChecked = useSetRecoilState(atoms.authChecked);
  const currentUser = useRecoilValue(atoms.user);
  const account = useRecoilValue(atoms.account);
  const [currentLocation, setCurrentLocation] = useRecoilState(
    atoms.currentLocation,
  );

  async function loadTile(query) {
    try {
      const res = await fetch(BASE_URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          sqlStatement: query,
          action: 'updateLocation',
          //   jwt: userObj.signInUserSession.idToken.jwtToken,
        }),
      });
      const r = await res.json();
      //   console.log(r);
      return r;
    } catch (error) {
      console.log('ERROR', error);
      return {};
    }
  }

  return {
    loadTile,
  };
}
