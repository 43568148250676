import React from 'react';
import Card from '@mui/material/Card';
import AddNewCamera from 'components/main/AddNewCamera';
import AddNewTileAccount from 'components/main/AddNewTileAccount';
import { useRecoilValue } from 'recoil';
import * as atoms from 'recoil/atoms';
import TileMenu from 'components/main/TileMenu';
import TileDatepicker from 'components/main/TileDatepicker';
import { useSnowflake } from 'hooks/use-snowflake';

export default function TileBase({ tile }) {
  const { loadTile } = useSnowflake();
  const [loading, setLoading] = React.useState(false);
  const [data, setData] = React.useState({});

  React.useMemo(() => {
    async function fetchData() {
      setLoading(true);
      const sql =
        'select DISTINCT uid from ACCOUNT_4275D101_8E63_4DDF_B954_22646D120923;';
      const d = await loadTile(sql);
      // console.log(d);
      setData(d?.data[0]);
      setLoading(false);
    }

    if (tile?.id === '519ebd46-da6f-4473-a4b7-b7919fec1940') {
      fetchData();
    }
  }, [tile]);

  return (
    <Card
      // elevation={6}
      // elevation={2}
      // variant="outlined"
      sx={{
        maxWidth: 250,
        // margin: 'auto',
        padding: '20px',
        paddingTop: '10px',
        // textAlign: 'center',
      }}
    >
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <TileMenu tile={tile} />
        <TileDatepicker tile={tile} />
      </div>
      <br></br>
      {loading ? (
        <div>Loading...</div>
      ) : (
        <>
          <div>
            {tile.name} {tile.id}
          </div>
          {data?.UID && (
            <>
              <br></br>
              <div>UID: {data?.UID}</div>
            </>
          )}
        </>
      )}
    </Card>
  );
}
