// import 'styles/loadingScreen.css';

// function LoadingScreen() {
//   // return (
//   //   <div style={{ textAlign: 'center' }}>
//   //     <br></br>
//   //     <br></br>
//   //     <br></br>
//   //     <br></br>
//   //     <br></br>
//   //     <br></br>
//   //     <br></br>
//   //     <br></br>
//   //     <br></br>
//   //     <br></br>
//   //     <br></br>
//   //     <div class="lds-ellipsis">
//   //       <div></div>
//   //       <div></div>
//   //       <div></div>
//   //       <div></div>
//   //     </div>
//   //   </div>
//   // );
//   return <div style={{ padding: '20px' }}>Loading...</div>;
// }

// export default LoadingScreen;

// change the imports to use @mui/material-ui

import React from 'react';
// import { makeStyles } from '@mui/material/styles';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

// const useStyles = makeStyles((theme) => ({
//   root: {
//     display: 'flex',
//     '& > * + *': {
//       marginLeft: theme.spacing(2),
//     },
//   },
// }));

export default function LoadingScreen() {
  // const classes = useStyles();

  return (
    <div style={{ textAlign: 'center' }}>
      <br></br>
      <br></br>
      <br></br>
      <br></br>

      <CircularProgress />
    </div>
    // <Box sx={{ display: 'flex' }}>
    //   {/* <div>Loading :)</div> */}
    //   {/* <Box sx={{ display: 'flex' }}>
    //     <Typography variant="caption">Loading...</Typography>
    //   </Box> */}
    // </Box>
  );
}
