export default function HowWeDoIt() {
  return (
    <>
      <div
        style={{
          // backgroundColor: '#222222',
          // backgroundColor: 'blue',
          padding: '40px',
          // color: 'white',
          textAlign: 'center',
          fontSize: '18px',
        }}
      >
        <div
          style={{
            maxWidth: '1400px',
            margin: 'auto',
            textAlign: 'left',
          }}
        >
          {/* <br></br>
          <h2 className="highlight_text_dark" style={{ textAlign: 'right' }}>
            How we do it
          </h2>
          <br></br> */}
          <div className="break_flex">
            <div style={{ width: '100%', padding: '10px 0px' }}>
              <div>
                We tap into the security cameras that you already have in place
                and use machine learning and artificial intelligence to track
                customers as they make their way through the store. We then use
                data science to make sense of the data and pull out suggestions
                on how to improve.
              </div>
              <br></br>
              <div>
                Best of all, we don't send out surveys that annoy customers and
                get ignored anyway.
              </div>
            </div>
            <div
              style={{
                width: '100%',
                // textAlign: 'left',
                padding: '10px 0px',
              }}
            >
              <h2
                className="highlight_text"
                // style={{ textAlign: 'right' }}
              >
                How we do it
              </h2>
              {/* <h5>
                Best of all, we don't send out surveys that annoy customers and
                get ignored anyway.
              </h5> */}
            </div>
          </div>
        </div>
      </div>

      {/* <div
        style={{
          // backgroundColor: '#222222',
          // backgroundColor: 'blue',
          padding: '40px',
          // color: 'white',
          textAlign: 'center',
          // fontSize: '24px',
        }}
      >
        <h3>How we do it</h3>
        <br></br>
        <h5>
          We tap into the security cameras that you already have in place and
          use Machine Learning and Artifical Intelligence to track customers as
          they make their way through the store. We then use Data Science to
          make sense of the data and pull out suggestions on how to improve.
        </h5>
        <div>
          Best of all, we don't send out surveys that annouy customers and get
          ignored anyway.
        </div>
      </div> */}
    </>
  );
}
