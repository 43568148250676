import React, { Suspense } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Design from 'components/helpers/Design';
import Redirect from 'components/helpers/Redirect';
import SignIn from 'components/helpers/SignIn';
import SignUp from 'components/helpers/SignUp';
import Landing from 'components/landing/Landing';
import LoadingScreen from 'components/helpers/LoadingScreen';
import ForgotPassword from 'components/helpers/ForgotPassword';
import AreasPage from 'components/pages/AreasPage';
import LocationPage from 'components/pages/LocationPage';
import AccountPage from 'components/pages/AccountPage';
import { useAuth } from 'hooks/use-auth';
import { useRecoilValue } from 'recoil';
import * as atoms from 'recoil/atoms';
import { useDynamo } from 'hooks/use-dynamo';
import { useLocation } from 'react-router-dom';

function App() {
  const { checkAuth } = useAuth();
  const user = useRecoilValue(atoms.user);
  const authChecked = useRecoilValue(atoms.authChecked);

  // useAuth();

  React.useEffect(() => {
    async function onLoad() {
      await checkAuth();

      // if (location.pathname.includes('/location/')) {
      //   const id = location.pathname.replace('/location/', '');
      //   const sk = `location#${id}`;
      //   if (id.length > 0 && sk !== currentLocation.sk) {
      //     const obj = {
      //       pk: account.pk,
      //       sk: sk,
      //     };
      //     // console.log('LocationPage', obj);
      //     loadLocation(obj);
      //   }
      // }
    }
    onLoad();
    // eslint-disable-next-line
  }, []);

  if (!authChecked) {
    return <LoadingScreen />;
  }

  if (!user.email) {
    return (
      <Suspense fallback={<div>Loading unauthed...</div>}>
        <BrowserRouter>
          <Routes>
            <Route exact path={`/`} element={<Landing />} />
            <Route exact path={`/signin`} element={<SignIn />} />
            <Route exact path={`/signup`} element={<SignUp />} />
            <Route
              exact
              path={`/forgotpassword`}
              element={<ForgotPassword />}
            />
            <Route exact path={`/design`} element={<Design />} />
            {/* <Route render={() => <Redirect to="/" />} /> */}
          </Routes>
        </BrowserRouter>
      </Suspense>
    );
  }

  return (
    <Suspense fallback={<div>Loading authed...</div>}>
      <BrowserRouter>
        <AuthedRoutes />
      </BrowserRouter>
    </Suspense>
  );
}

export default App;

function AuthedRoutes() {
  const location = useLocation();
  const currentLocation = useRecoilValue(atoms.currentLocation);
  const { loadLocation } = useDynamo();
  const account = useRecoilValue(atoms.account);

  React.useEffect(() => {
    async function onLoad() {
      // console.log('in here', account.pk, location.pathname);
      if (location.pathname.includes('/location/') && account.pk) {
        const id = location.pathname
          .replace('/location/', '')
          .replace('/areas', '');
        const sk = `location#${id}`;
        // console.log(sk);
        if (id.length > 0 && sk !== currentLocation?.sk) {
          const obj = {
            pk: account.pk,
            sk: sk,
          };
          // console.log('LocationPage', obj);
          loadLocation(obj);
        }
      }
    }
    onLoad();
    // eslint-disable-next-line
  }, [account.pk, location.pathname]);
  return (
    <Routes>
      {/* <Route exact path={'/fake'}>
            <FakeStoreMap />
          </Route>
          <Route exact path={`/data`}>
            <TrackingData />
          </Route> */}

      {/* <Route exact path={`/:locationId/camera/:cameraId`}>
            <CameraPage />
          </Route> */}
      {/* <Route path={[`/location/:locationId`, '/cameras', '/areas']}> */}

      <Route exact path={`/location/:locationId`} element={<LocationPage />} />
      <Route
        exact
        path={`/location/:locationId/areas`}
        element={<AreasPage />}
      />
      <Route exact path="/" element={<AccountPage />} />

      {/* <Route render={() => <Redirect to="/" />} /> */}
      <Route path="/*" element={<Redirect to="/" />} />
    </Routes>
  );
}
