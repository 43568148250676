import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Paper from '@mui/material/Paper';

const colors0 = ['#191919', '#0036F9', '#05BDFF'];
const colors1 = ['#191919', '#0852FD', '#02B9F0'];
const colors2 = [
  '#00B657',
  '#C3E438',
  '#FEF125',
  // '#F79F1E',
  '#FE7007',
  '#EA2026',
];
const colors3 = [
  '#0852FD',
  '#02B9F0',
  '#FDA7DF',
  '#9980FA',
  '#5658BB',
  '#823471',
];
const colors4 = ['#C534FA', '#D980FA', '#1B1364', '#ED4C67'];
const colors5 = [
  '#0036F9',
  '#05BDFF',
  '#FDA7DF',
  '#9980FA',
  '#5658BB',
  '#823471',
];

function Design() {
  const navigate = useNavigate();

  function showColors(colors = [], height = 50, width = 100, gap = 0) {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          gap: `${gap}px`,
        }}
      >
        {colors.map((color) => {
          return (
            <div
              key={color}
              style={{
                backgroundColor: color,
                width: `${width}px`,
                height: `${height}px`,
              }}
            ></div>
          );
        })}
      </Box>
    );
  }

  return (
    <div style={{ padding: '20px' }}>
      <button onClick={() => navigate('/dashboard')}>dashboard</button>
      <button onClick={() => navigate('/data')}>data</button>
      <br></br>
      <br></br>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          gap: '10px',
        }}
      >
        <div
          style={{
            backgroundColor: '#191919',
            width: '150px',
            height: '100px',
          }}
        ></div>
        <div
          style={{
            backgroundColor: '#0036F9',
            width: '150px',
            height: '100px',
          }}
        ></div>
        <div
          style={{
            backgroundColor: '#05BDFF',
            width: '150px',
            height: '100px',
          }}
        ></div>
        {/* <div
          style={{
            backgroundColor: 'yellow',
            // backgroundColor: '#E6F509',
            width: '150px',
            height: '100px',
          }}
        ></div> */}
      </Box>
      <br></br>
      {showColors(colors0)}
      <br></br>
      {showColors(colors1)}
      <br></br>
      {showColors(colors2)}
      <br></br>
      {showColors(colors3)}
      <br></br>
      {showColors(colors4)}
      <br></br>
      {showColors(colors5)}
      <br></br>

      <h3 className={'logo'}>Ambul</h3>
      <h3 className={'logo'}>ambul.ai</h3>
      <h1>This is an h1</h1>
      <h2>This is an h2</h2>
      <h3>This is an h3</h3>
      <h4>This is an h4</h4>
      <h5>This is an h5</h5>
      <div>
        Regular font goes here. This is going to be a lot of text. lskdjfl .,s
        lkjs .sjslkj sldkjflskjd flksjdfpaoijcvpoaiyfvqm kwe jfo
      </div>
      <div>
        Regular font goes here. This is going to be a lot of text. lskdjfl .,s
        lkjs .sjslkj sldkjflskjd flksjdfpaoijcvpoaiyfvqm kwe jfo
      </div>
      <div>
        Regular font goes here. This is going to be a lot of text. lskdjfl .,s
        lkjs .sjslkj sldkjflskjd flksjdfpaoijcvpoaiyfvqm kwe jfo
      </div>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          // justifyContent: 'space-between',
          alignItems: 'center',
          //   justifyContent: 'space-around',
          gap: '10px',
          // backgroundColor: 'red',
        }}
      >
        <Button onClick={() => console.log('cool')}>Sign in</Button>
        <Button onClick={() => console.log('cool')} variant="outlined">
          Sign up
        </Button>
        <Button
          onClick={() => console.log('cool')}
          color="primary"
          variant="contained"
        >
          Sign out
        </Button>
        <Button onClick={() => console.log('cool')} variant="black_outlined">
          Sign out
        </Button>
        <Button onClick={() => console.log('cool')} variant="black_solid">
          Sign out
        </Button>
        <div style={{ backgroundColor: 'black', padding: '10px' }}>
          <Button onClick={() => console.log('cool')} variant="white">
            Sign out
          </Button>
        </div>
      </Box>
      <br></br>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          gap: '10px',
        }}
      >
        <Paper sx={{ padding: '10px', marginLeft: '20px', width: '200px' }}>
          this is paper
        </Paper>
        <Paper
          elevation={2}
          sx={{ padding: '10px', marginLeft: '20px', width: '200px' }}
        >
          this is paper 2
        </Paper>
        <Paper
          elevation={4}
          sx={{ padding: '10px', marginLeft: '20px', width: '200px' }}
        >
          this is paper 4
        </Paper>
        <Paper
          elevation={6}
          sx={{ padding: '10px', marginLeft: '20px', width: '200px' }}
        >
          this is paper 6
        </Paper>
      </Box>
      <br></br>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          gap: '10px',
        }}
      >
        <Card sx={{ padding: '10px', marginLeft: '20px', width: '200px' }}>
          this is a card not done
        </Card>
        <Card
          variant="outlined"
          sx={{ padding: '10px', marginLeft: '20px', width: '200px' }}
        >
          this is a card outline
        </Card>
        <Card
          variant="dashed"
          sx={{ padding: '10px', marginLeft: '20px', width: '200px' }}
        >
          this is a card example
        </Card>
      </Box>
    </div>
  );
}

export default Design;
