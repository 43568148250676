import { useNavigate } from 'react-router-dom';
import { useAuth } from 'hooks/use-auth';
import { useRecoilState } from 'recoil';
import * as atoms from 'recoil/atoms';

function ForgotPassword() {
  const [email, setEmail] = useRecoilState(atoms.forgotPasswordEmail);
  const [error, setError] = useRecoilState(atoms.forgotPasswordError);
  const { onForgotPassword } = useAuth();
  const navigate = useNavigate();

  function onEmailChange(event) {
    setEmail(event.target.value);
    if (error) {
      setError('');
    }
  }

  return (
    <>
      <div style={{ textAlign: 'center', padding: '20px' }}>
        <div>forgot password</div>
        <br></br>
        <br></br>
        <input value={email} type="text" onChange={onEmailChange} />
        <br></br>
        <br></br>
        <div style={{ color: 'red' }}>{error}</div>
        <br></br>
        <button onClick={() => onForgotPassword()}>reset password</button>
        <br></br>
        <br></br>
        <div onClick={() => navigate('/')}>sign in</div>
      </div>
    </>
  );
}

export default ForgotPassword;
