import React from 'react';
import AccountPrompt from 'components/main/AccountPrompt';
import DashboardLayout from 'components/helpers/DashboardLayout';
import AccountTilesContainer from 'components/main/AccountTilesContainer';

export default function AccountPage() {
  return (
    <DashboardLayout page="account">
      <br></br>
      <br></br>
      <AccountPrompt />
      <AccountTilesContainer />
    </DashboardLayout>
  );
}
